export const GET_ANALYSIS_INIT = 'GET_ANALYSIS_INIT'
export const GET_ANALYSIS_SUCCESS = 'GET_ANALYSIS_SUCCESS'
export const GET_ANALYSIS_FAILURE = 'GET_ANALYSIS_FAILURE'

export const GET_SAMPLES_INIT = 'GET_SAMPLES_INIT'
export const GET_SAMPLES_SUCCESS = 'GET_SAMPLES_SUCCESS'
export const GET_SAMPLES_FAILURE = 'GET_SAMPLES_FAILURE'

export const POPULAR_SCREENS_INIT = 'POPULAR_SCREENS_INIT'
export const POPULAR_SCREENS_SUCCESS = 'POPULAR_SCREENS_SUCCESS'
export const POPULAR_SCREENS_FAILURE = 'POPULAR_SCREENS_FAILURE'

export const FETCH_RESISTANCE = 'FETCH_RESISTANCE'
export const FETCH_RESISTANCE_SUCCESS = 'FETCH_RESISTANCE_SUCCESS'
export const FETCH_RESISTANCE_FAILURE = 'FETCH_RESISTANCE_FAILURE'
