import {
  UPDATE_NOTIFICATIONS,
  RESET_NOTIFICATION_COUNT,
  TOGGLE_TERMS_PAGE,
  REFRESH_ALGO,
  REFRESH_SCANS,
  FETCH_PROFILE_CAROUSEL,
  FETCH_PROFILE_CAROUSEL_SUCCESS,
  FETCH_PROFILE_CAROUSEL_FAILURE,
  TOGGLE_ROOT_MODAL,
  POLL_USER,
  UPDATE_WEBSITE_CONFIG,
  TOGGLE_AUTH_GREETING,
  TOGGLER_TOUR,
} from './actionTypes'

export function updateNotifications(data) {
  return {
    type: UPDATE_NOTIFICATIONS,
    notification: data,
  }
}

export function clearNotifications() {
  return {
    type: RESET_NOTIFICATION_COUNT,
  }
}

export function toggleTerms(terms_uri, heading) {
  return {
    type: TOGGLE_TERMS_PAGE,
    terms_uri,
    heading,
  }
}

export function pollUser(params, headers) {
  return {
    type: POLL_USER,
    params,
    headers,
  }
}

export function refreshAlgos(field, value) {
  return {
    type: REFRESH_ALGO,
    field,
    value,
  }
}

export function refreshScans(shouldRefresh) {
  return {
    type: REFRESH_SCANS,
    shouldRefresh,
  }
}

export function profileCarousel() {
  return {
    type: FETCH_PROFILE_CAROUSEL,
  }
}

export function profileCarouselSuccess(data) {
  return {
    type: FETCH_PROFILE_CAROUSEL_SUCCESS,
    data,
  }
}
export function profileCarouselFailure(error) {
  return {
    type: FETCH_PROFILE_CAROUSEL_FAILURE,
    error,
  }
}

// export function toggleAppExit(showAppExit) {
//   return (dispatch) => {
//     return (dispatch({
//       type: TOGGLE_APP_EXIT,
//       showAppExit,
//     }))
//   }
// }

export function toggleRootModal(modalName, showModal) {
  return {
    type: TOGGLE_ROOT_MODAL,
    modalName,
    showModal,
  }
}

export function updateWebSiteConfig(field, config) {
  return {
    type: UPDATE_WEBSITE_CONFIG,
    field,
    config,
  }
}

export function toggleAuthGreeting(modalType, routeAfterActivation) {
  return {
    type: TOGGLE_AUTH_GREETING,
    modalType,
    routeAfterActivation,
  }
}

export function toggleTour(params) {
  return {
    type: TOGGLER_TOUR,
    params,
  }
}
