import React, { Component } from 'react'
import classnames from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/'
import SearchIcon from '../../assets/icons/SearchIcon'

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentActieIndex: -1,
      show_list: false,
    }
  }

  keyboardNavHandler = (e) => {
    const {
      items,
      onItemSelected,
    } = this.props
    const { currentActieIndex } = this.state
    const len = items.length
    const { key } = e
    let newIndex = -1
    if (key === 'ArrowDown') {
      newIndex = currentActieIndex + 1
      if (newIndex < len) {
        this.setState({ currentActieIndex: newIndex })
      } else {
        this.setState({ currentActieIndex: -1 })
      }
    } else if (key === 'ArrowUp') {
      newIndex = currentActieIndex - 1
      if (newIndex > -2) {
        this.setState({ currentActieIndex: newIndex })
      } else {
        this.setState({ currentActieIndex: len - 1 })
      }
    } else if (key === 'Enter' && currentActieIndex !== -1) {
      this.setState({ currentActieIndex: -1 })
      onItemSelected(items[currentActieIndex])
    } else if (key === 'Escape') {
      this.onDismiss()
      this.setState({ currentActieIndex: -1, })
    }
  }

  onDismiss = () => {
    this.setState({ show_list: false })
  }

  render() {
    const {
      classes = {},
      onChange = () => { },
      value = '',
      onItemSelected = () => { },
      items = [],
      focusHandler,
      extraClass = '',
      boxClass = '',
      popupClass = '',
      accessor = 'algo_name',
      key = 'algo_uuid',
    } = this.props
    const { currentActieIndex, show_list } = this.state
    return (
      <div
        className={classnames(classes.container, extraClass)}
        id="autocomplete"
      >
        <div
          className={classnames(
            classes.inputContainer,
            show_list && items.length ? classes.active : '',
            value.length ? classes.active : '',
          )}
          tabIndex="0"
        >
          <SearchIcon color="#666666" width={18} height={18} />
          <input
            placeholder="Search algos"
            value={value}
            onChange={e => onChange(e.target.value)}
            onFocus={() => focusHandler()}
            onKeyUp={e => this.keyboardNavHandler(e)}
            className={classnames(classes.field, boxClass)}
          />
        </div>
        {show_list && items.length
          ? (
            <ClickAwayListener
              onClickAway={() => this.onDismiss()}
            >
              <Paper
                className={classnames(classes.autocomplete_container, popupClass)}
              >
                {items.map((item, i) => {
                  return (
                    <MenuItem
                      onClick={() => onItemSelected(item)}
                      key={`${item[key]}`}
                      className={classnames(
                        classes.item,
                        currentActieIndex === i ? classes.activeHighlighted : ''
                      )}
                    >
                      {`${item[accessor]}`}
                    </MenuItem>
                  )
                })}
              </Paper>
            </ClickAwayListener>
          ) : null}
      </div>
    )
  }
}

const styles = theme => ({
  container: {
    position: 'relative',
  },
  inputContainer: {
    display: 'flex',
    transition: 'width 0.4s ease-in-out',
    borderBottom: `1px solid ${theme.underline}`,
    alignItems: 'center',
    outline: 'none',
    width: 200,
    '&:hover': {
      width: '300px',
      borderBottom: `1px solid ${theme.greyLight_4}`,
    },
    '&:focus': {
      width: '300px',
      borderBottom: `2px solid ${theme.blue} !important`,
    },
  },
  field: {
    width: '100%',
    height: '40px',
    outline: 'none',
    boxSizing: 'border-box',
    fontSize: '14px',
    padding: '3px 12px 0px 16px',
    border: 'none',
    lineHeight: 1,
    textAlign: 'left',
    '&::placeholder': {
      color: theme.textLight,
      fontWeight: 300,
      fontSize: 13,
    },
  },
  active: {
    width: 300,
    borderBottom: `2px solid ${theme.blue}`
  },
  autocomplete_container: {
    width: 300,
    maxHeight: 300,
    overflowX: 'auto',
    padding: '0',
    position: 'absolute',
    top: 42,
    zIndex: 2000,
    borderRadius: 2,
    boxShadow: '0 8px 30px 0 rgba(0, 0, 0, 0.15)',
  },
  hidden: {
    display: 'none',
  },
  item: {
    fontSize: 13,
    color: theme.textDark_2,
    borderBottom: `1px solid ${theme.greyLight}`,
    '&:hover': {
      backgroundColor: theme.hover,
    }
  },
  activeHighlighted: {
    backgroundColor: theme.hover,
  },
})

export default withStyles(styles)(Search)
