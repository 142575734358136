import {
  GET_ARCHIVES_INIT,
  GET_ARCHIVES_SUCCESS,
  GET_ARCHIVES_FAILURE,
} from './actionTypes'

export function getArchivesSuccess(data) {
  return {
    type: GET_ARCHIVES_SUCCESS,
    data
  }
}

export function getArchivesFailure(error) {
  return {
    type: GET_ARCHIVES_FAILURE,
    error
  }
}

export function getArchives(params, headers) {
  const payload = {
    params, headers
  }
  return {
    type: GET_ARCHIVES_INIT,
    payload
  }
}
