import React from 'react'
import Loader from '../../../UI/Loader/Loader'
import TechnicalGraph from '../../../UI/TechnicalGraph'
import CustomText from '../../../UI/CustomText'
import { withTheme } from '../../../Theme/ThemeProvider'
import { DIMENSIONS, theme } from '../../../Theme'

const Summary = ({
  analysis: {
    buy = 0,
    sell = 0,
    neutral = 0,
    ema20 = 0.0,
    sma20 = 0.0,
    rsi = 0.0,
    macd = 0.0,
    cci = 0.0,
    awesome_oscillator = 0.0,
  },
  isFetching,
  // classes,
  isDark,
  styles,
}) => {
  const item = (text, value, classname) => {
    return (
      <div className={styles[classname]}>
        <CustomText className={styles.text}>{text}</CustomText>
        <CustomText className={styles.value}>{value}</CustomText>
      </div>
    )
  }
  const neg_sell = -1 * sell
  const pct = (buy + neg_sell) / (buy + sell + neutral)
  const leftPosition = Math.abs((pct * 50) + 50)
  return (
    <div className={styles.summaryContainer}>
      {isFetching ? (
        <div className={styles.pageLoader}>
          <Loader />
        </div>
      ) : null}
      <TechnicalGraph
        leftPosition={leftPosition || 0}
        buy={buy}
        graphViewStyles={styles.graphView}
        sell={sell}
        neutral={neutral}
        showBuySellInfo
        isDark={isDark}
      />
      <div className={styles.techSummary}>
        <div className={styles.row}>
          {item('EMA (20)', ema20.toFixed(2), 'itemL')}
          {item('SMA (20)', sma20.toFixed(2), 'itemR')}
        </div>
        <div className={styles.row}>
          {item('RSI (14)', rsi.toFixed(2), 'itemL')}
          {item('Awesome Osc.', awesome_oscillator.toFixed(2), 'itemR')}
        </div>
        <div className={styles.row}>
          {item('Macd (12, 26, 9)', macd.toFixed(2), 'itemL')}
          {item('CCI (20)', cci.toFixed(2), 'itemR')}
        </div>
      </div>
    </div>
  )
}

const stylesheet = {
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '7.25rem',
    position: 'relative',
    padding: '4em 3em',
  },
  techSummary: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '240px',
    // justifyContent: 'center'
  },
  row: { display: 'flex', padding: '0.75rem 0.25rem', flexDirection: 'row' },
  itemL: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flex: 1
  },
  itemR: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flex: 1
  },
  graphView: {
    width: DIMENSIONS.WIDTH - 40,
  },
  text: { fontSize: '0.875rem', color: theme.textDark },
  value: { fontSize: '1rem', color: theme.textDark_2 },
  pageLoader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: theme.background,
    zIndex: 999,
    display: 'flex',
    top: 1,
    right: 0,
  },
  '@media screen and (max-width: 600px)': {
    summaryContainer: {
      flexDirection: 'column',
      marginTop: 0,
      padding: '2rem',
      // height: 'calc(100vh - 200px)',
      overflow: 'auto',
    },
    itemL: { alignItems: 'flex-start' }
  }
}

// export default withStyles(styles)(Summary)
export default withTheme(stylesheet)(Summary)
