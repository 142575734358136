import React, { Fragment } from 'react'
import classnames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { withStyles } from '@material-ui/core'
import Typography from '../../UI/Typography/Typography'

const Pagination = ({
  classes,
  totalPages = 0,
  currentPage = 0,
  jumpToPage,
  showPageSizeChange = false,
  page_limit = 10,
  pageLimitChanged,
  noBorder = false
}) => {
  const firstdisabled = currentPage === 0
  const lastDisabled = currentPage + 1 >= totalPages
  // const displayPage = totalPages === 0 ? 0 : currentPage + 1
  const values = [10, 25, 50, 100]
  return (
    <div className={classnames(classes.container, noBorder ? '' : classes.border)}>
      {showPageSizeChange
        ? (
          <>
            <Typography variant="normal">
              Rows per page
            </Typography>
            <Select
              value={page_limit}
              onChange={pageLimitChanged}
              className={classes.pageLimit}
              disableUnderline
            >
              {values.map(value => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>
            <div className={classes.divider} />
          </>
        ) : null}
      <IconButton
        disabled={firstdisabled}
        onClick={() => jumpToPage(-totalPages + 1)}
        classes={{
          root: classes.root,
        }}
      >
        <FirstPageIcon style={{ fontSize: 20 }} />
      </IconButton>
      <div className={classes.divider} />
      <IconButton
        disabled={firstdisabled}
        onClick={() => jumpToPage(-1)}
        classes={{
          root: classes.root,
        }}
      >
        <KeyboardArrowLeft style={{ fontSize: 20 }} />
      </IconButton>
      <div className={classes.divider} />
      <Typography variant="normal" extraStyles={classes.pages}>
        <span className={classes.displayPage}>{currentPage}</span>
        {`  of ${totalPages}`}
      </Typography>
      <div className={classes.divider} />
      <IconButton
        disabled={lastDisabled}
        onClick={() => jumpToPage(1)}
        classes={{
          root: classes.root,
        }}
      >
        <KeyboardArrowRight style={{ fontSize: 20 }} />
      </IconButton>
      <div className={classes.divider} />
      <IconButton
        disabled={lastDisabled}
        onClick={() => jumpToPage(totalPages - 1)}
        classes={{
          root: classes.root,
        }}
      >
        <LastPageIcon style={{ fontSize: 20 }} />
      </IconButton>
    </div>
  )
}

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #f3f3f3',
    justifyContent: 'flex-end',
    height: 40,
  },
  border: {
    border: '1px solid #f3f3f3',
  },
  pageLimit: {
    margin: '0 0.75rem',
  },
  pages: {
    margin: '0 1rem',
  },
  root: {
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 0,
  },
  divider: {
    height: '100%',
    borderLeft: '1px solid #f3f3f3',
  },
  displayPage: {
    color: '#253bde',
    marginRight: '0.4rem',
  }
})

export default withStyles(styles)(Pagination)
