import { makeStyles } from '@material-ui/styles'
import React, {
  useRef, useCallback, useEffect, // useState
} from 'react'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

import CustomText from './CustomText'
// import PlaceHolderLoader from './PlaceHolderLoader'

import { SPACING, theme } from '../Theme'
import { useTheme } from '../Theme/ThemeProvider'

// let initialMount = true

const TabView = ({
  tabs,
  tabChangeHandler,
  // defaultActiveTab,
  activeTab,
  style,
  tabStyles,
  activeTabStyles,
  barStyles,
  //   loading,
  //   loadingText,
  //   isDark,
  labelKey,
  valueKey,
  labelRenderer,
  extraData,
  loading,
}) => {
  const barRef = useRef(null)
  const selectedBtnRef = useRef(null)
  const styles = stylesheet()
  const { isDark } = useTheme()
  useEffect(() => {
    measureLayoutDim()
  }, [activeTab, extraData])

  // const [activeTab, toggleTab] = useState(defaultActiveTab)
  const debouncedHandler = useCallback(
    debounce((e, value, item, index) => tabChangeHandler(value, item, index), 300), [],
  )
  const measureLayoutDim = () => {
    if (activeTab !== undefined && tabs.length > 0 && barRef.current && selectedBtnRef.current) {
      // initialMount = false
      const { offsetLeft, offsetWidth } = selectedBtnRef.current
      barRef.current.style.left = `${offsetLeft + (offsetWidth * (0.35))}px`
      barRef.current.style.width = `${offsetWidth / 4}px`
    }
  }
  const onTabChange = (e, value, item, index) => {
    e.stopPropagation()
    e.preventDefault()
    if (value === activeTab) return
    // toggleTab(value)
    debouncedHandler(e, value, item, index)
  }
  if (loading) {
    return (
      <div className={classnames(styles.tabViewContainer, style)}>
        {/* <PlaceHolderLoader className={styles.loader} /> */}
      </div>
    )
  }
  const activeTabStyle = classnames(styles.activeTabViewBtn, activeTabStyles)
  return (
    <div className={classnames(styles.tabViewContainer, style)}>
      <div id="tabViewBar" ref={barRef} className={classnames(styles.bar, barStyles)} />
      {tabs.map((item, index) => {
        let label = item
        let value = item
        if (typeof item === 'object') {
          label = labelKey ? item[labelKey] : item.label
          value = valueKey ? item[valueKey] : item.value
        }
        const active = activeTab === value
        const textProps = active ? {
          size: 'regular', weight: 'semi_bold', color: isDark ? 'text' : 'linkColor',
        } : {
          size: 'small', weight: 'medium', color: isDark ? 'textSecondary' : 'text',
        }
        return (
          <button
            key={value}
            id={value}
            name={value}
            ref={active ? selectedBtnRef : null}
            type="button"
            onClick={(e) => { if (!active) onTabChange(e, value, item, index) }}
            className={classnames(styles.tabViewBtn, tabStyles, active ? activeTabStyle : '')}
            // style={active ? { transform: 'scale(1.1)' } : {}}
            disabled={active}
          >
            {labelRenderer ? labelRenderer({
              label, item, value, index, labelProps: textProps,
            }) : (
              <CustomText
                className={styles.btnText}
                center
                {...textProps}
              >
                {label}
              </CustomText>
            )}
          </button>
        )
      })}
    </div>
  )
}

const stylesheet = makeStyles({
  tabViewContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minHeight: 27,
    flexWrap: 'wrap',
  },
  loader: {
    height: 20,
    maxWidth: 240,
  },
  bar: {
    position: 'absolute',
    borderRadius: 50,
    height: 4,
    left: 0,
    opacity: 1,
    bottom: '-3px',
    backgroundColor: theme.linkWhite,
    transition: 'all 0.2s var(--anim-func-ease)',
  },
  tabViewBtn: {
    border: `1px solid ${theme.borderColor}`,
    padding: `${SPACING.SPACE_2} ${SPACING.SPACE_20}`,
    borderRadius: 4,
    marginLeft: SPACING.SPACE_10,
    '& :first-child': {
      margin: 0,
    },
    '& > *': {
      transition: 'all 0.2s var(--anim-func-ease)',
    },
  },
  activeTabViewBtn: {
    borderColor: 'transparent',
  },
  '@media screen and (max-width: 600px)': {
    tabViewContainer: {
      paddingTop: '20px',
    },
  },
  '@media screen and (max-width: 500px)': {
    btnText: {
      fontSize: '10px !important',
    },
  },
})

export default TabView
