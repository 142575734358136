export const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 1rem 0 1rem'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  right_header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})
