/* eslint-disable import/no-cycle */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import info from './containers/Info/Info-reducer'
import archive from './containers/Archive/Archive-reducer'
import common from './containers/Common/Common-reducer'
import instruments from './containers/Instruments/Instruments-reducer'

import rootSaga from './rootSaga'

/* eslint-disable */
const rootReducer = combineReducers({
  info,
  archive,
  common,
  instruments,
})

let enhancer

const sagaMiddleware = createSagaMiddleware()

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(sagaMiddleware)
} else {
  const composeEnhancers =
    typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        maxAge: 25
      }) : compose
  enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
}

const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export default store
