import React, { PureComponent } from 'react'

import CustomText from './CustomText'
import HighlightedText from './HighlightedText'

import {
  ASSETS, SPACING,
} from '../Theme'
import { technicalslabelMap } from '../utils/consts'
import { withTheme } from '../Theme/ThemeProvider'

// const offsetMultiplier = {
//   x: { left: 0, right: 0 },
//   n: { left: -1, right: -2 },
// }

const {
  POINTER, XS_POINTER_DARK, BAR, BAR_MA, BAR_OSCILlATOR, XS_POINTER,
  XS_BAR, POINTER_DARK,
} = ASSETS

// do not change the keys
export const GRAPH_TYPE_MAP = {
  MA: BAR_MA,
  DEFAULT: BAR,
  OSCILLATOR: BAR_OSCILlATOR,
}

const CircleLoader = () => null

class TechnicalGraph extends PureComponent {
  constructor(props) {
    super(props)
    // this.graphViewWidth = props.graphViewWidth || 0
    this.pointerWidth = props.pointerWidth || 40
    this.pointerHeight = props.isDark ? '50%' : '30%'
    this.pointerRef = React.createRef()
    this.graphViewRef = React.createRef()

    this.state = {
      leftPos: '50%',
      // eslint-disable-next-line react/no-unused-state
      graphWidth: 0,
    }
    this.pointerSrc = props.isDark ? POINTER_DARK : POINTER
    this.pointerHeightMultiplier = 10
    this.barImageSrc = GRAPH_TYPE_MAP[props.barType] || GRAPH_TYPE_MAP.DEFAULT
    this.sepWidth = 16 // bar seperation width
  }
  // `${50 - ((this.pointerWidth / 2) * (100 / this.graphViewWidth))}%`,

  componentDidMount() {
    if (this.graphViewRef.current) {
      const { isDark } = this.props
      const { width } = this.graphViewRef.current.getBoundingClientRect()
      if (width <= 100) {
        this.barImageSrc = XS_BAR
        this.pointerSrc = isDark ? XS_POINTER_DARK : XS_POINTER
        this.sepWidth = 8
        this.pointerHeight = isDark ? 16 : 10
      }
      this.setPointerPos(width)
    }
  }

  componentDidUpdate(prevProps) {
    const { leftPosition } = this.props
    if (leftPosition !== prevProps.leftPosition && this.graphViewRef.current) {
      const { width } = this.graphViewRef.current.getBoundingClientRect()
      this.setPointerPos(width)
    }
  }

  setPointerPos = (graphWidth) => {
    if (graphWidth) {
      const { leftPosition } = this.props
      // this.pointerHeight = typeof this.pointerHeight === 'string'
      //   ? `${((Math.abs(50 - leftPosition) / 2.5) + 20)}%`
      //   : this.pointerHeight
      const barSepWidth = graphWidth / this.sepWidth // change this if the bar lines count change
      const posFromLeft = leftPosition * (graphWidth / 100)
      const pos = posFromLeft % barSepWidth
      let final_pos = 50
      if (pos > (barSepWidth / 2)) {
        final_pos = Math.ceil(posFromLeft / barSepWidth) * barSepWidth
      } else {
        final_pos = Math.floor(posFromLeft / barSepWidth) * barSepWidth
      }
      // const offset = posFromLeft > graphWidth / 2 ? offsetMultiplier.x.right
      //   : offsetMultiplier.x.left
      const pointerOffset = this.pointerWidth / 2 // offset
      this.setState({ leftPos: final_pos - pointerOffset })
    }
  }

  renderCountCell = (label, value, isDark) => {
    const { barType = 'DEFAULT' } = this.props
    const {
      text, icon, bgDark, bg,
    } = technicalslabelMap[label][barType]
    const { styles } = this.props
    return (
      <div className={styles.countCell}>
        <CustomText weight={isDark ? 'semi_bold' : ''} className={styles.countCellText}>{value}</CustomText>
        <HighlightedText
          color={text}
          backgroundColor={isDark ? bgDark : bg}
          text={label}
          iconName={icon}
          iconColor={text}
          iconSize={14}
          styles={{
            padding: `${SPACING.SPACE_2} ${SPACING.SPACE_12}`,
            borderRadius: 4,
            marginTop: SPACING.SPACE_8,
          }}
        />
      </div>
    )
  }

  render() {
    const {
    //  graphViewStyles,
      showBuySellInfo,
      showBuySell,
      buy,
      sell,
      neutral,
      graphHeight = 100,
      waiting,
      isDark,
      styles,
      graphViewWidth = 'unset',
    } = this.props
    const { leftPos } = this.state
    if (waiting) {
      return <CircleLoader size="small" />
    }
    const PointerSvg = this.pointerSrc
    return (
      <div className={styles.container}>
        {showBuySell ? (
          <div className={styles.buySellLabel}>
            <CustomText className={styles.sellLabel}>BEARISH</CustomText>
            <CustomText className={styles.neturalLabel}>NEUTRAL</CustomText>
            <CustomText className={styles.buyLabel}>BULLISH</CustomText>
          </div>
        ) : null}
        <div
          className={styles.graphView}
          ref={this.graphViewRef}
          style={{ width: graphViewWidth }}
        >
          <img
            src={this.barImageSrc}
            className={styles.graph}
            style={{ maxHeight: graphHeight }}
            alt="technical_img"
          />
          <img
            src={PointerSvg}
            className={styles.pointer}
            style={{ left: leftPos }}
            width={this.pointerWidth}
            height={this.pointerHeight}
            alt="technicals_pointer"
          />
        </div>
        {showBuySellInfo ? (
          <div className={styles.buySellCount}>
            {this.renderCountCell('Bearish', sell, isDark)}
            {this.renderCountCell('Neutral', neutral, isDark)}
            {this.renderCountCell('Bullish', buy, isDark)}
          </div>
        ) : null}
      </div>
    )
  }
}

const stylesheet = {
  container: {
  },
  graphView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // margin: 'auto',
    position: 'relative',
  },
  graph: {
    width: '100%',
  },
  pointer: {
    position: 'absolute',
    // zIndex: 3,
    transition: 'left 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
  },
  buySellLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: SPACING.SPACE_10,
  },
  buyLabel: {
    color: '#06d092',
  },
  neturalLabel: {
    color: '#6e6e6e',
  },
  sellLabel: {
    color: '#f55b12',
  },
  buySellCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${SPACING.SPACE_20} 0 0`,
  },
  countCell: {
    textAlign: 'center',
  },
}

export default withTheme(stylesheet)(TechnicalGraph)
