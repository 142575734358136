import React, { Component } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Checkbox from '@material-ui/core/Checkbox'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import { withStyles } from '@material-ui/core'
import CheckboxIcon from '../../assets/icons/Checkbox'
import CheckedBox from '../../assets/icons/CheckedBox'

class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      position: false,
      indicator: false,
      chart_type: false,
      time_frame: false,
      selection: {
        position: [],
        indicators: [],
        chart_type: [],
        time_frame: [],
      }
    }
    this.filterItems = {
      position: ['Bullish', 'Bearish'],
      indicators: [
        'Moving averages',
        'Oscillators',
        'Trend',
        'Volatility',
      ],
      chart_type: [
        'Candlestick',
        'Heikin-Ashi',
        'Renko',
      ],
      time_frame: [
        'min', '5min', '10min', '15min', 'hour', 'day',
      ]
    }
  }

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ open: false })
  }

  toggleState = (section) => {
    this.setState(prevState => ({ [section]: !prevState[section] }))
  }

  filterSelection = (field, name, value) => {
    const { selection } = this.state
    const newSelection = { ...selection }
    if (value) {
      newSelection[field].push(name)
    } else {
      newSelection[field] = newSelection[field].filter(indi => indi !== name)
    }
    this.setState({ selection: newSelection })
  }

  isSelected = (item, name) => {
    const { selection } = this.state
    if (selection[item].indexOf(name) !== -1) {
      return true
    }
    return false
  }

  applyFilter = () => {
    const { filterChanged } = this.props
    const { selection } = this.state
    const params = {
      chart_type: selection.chart_type,
      time_frame: selection.time_frame,
      position_type: selection.position,
      entry_logic: selection.indicators,
      exit_logic: selection.indicators,
    }
    filterChanged(params)
  }

  render() {
    const { classes } = this.props
    const { open, } = this.state
    return (
      <div className={classes.filterSection}>
        <Button
          className={classes.filterbutton}
          buttonRef={(node) => {
            this.anchorEl = node
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={() => this.toggleState('open')}
        >
          Filter
        </Button>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ marginLeft: -36, transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div className={classes.filtercontainer}>
                    {Object.keys(this.filterItems).map((item) => {
                      return (
                        <MenuList
                          key={item}
                        >
                          <MenuItem
                            className={classes.menuItem}
                            onClick={() => this.toggleState(item)}
                          >
                            {item.replace('_', ' ')}
                            {this.state[item] ? <ExpandLess /> : <ExpandMore />}
                          </MenuItem>
                          <Collapse
                            in={this.state[item]}
                            timeout="auto"
                            unmountOnExit
                            classes={{
                              entered: classes.collapse
                            }}
                          >
                            {this.filterItems[item].map((option) => {
                              return (
                                <div
                                  key={option}
                                  className={classes.optionsItem}
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={this.isSelected(item, option)}
                                    icon={
                                      <CheckboxIcon
                                        width={22}
                                        height={22}
                                        color="#f3f3f3"
                                      />
                                    }
                                    checkedIcon={<CheckedBox width={22} height={22} />}
                                    className={classes.checkbox}
                                    onChange={e => this.filterSelection(item, option, e.target.checked)}
                                    disableRipple
                                  />
                                  {option}
                                </div>
                              )
                            })}
                          </Collapse>
                        </MenuList>
                      )
                    })}
                    <div className={classes.button}>
                      <Button
                        onClick={() => this.applyFilter()}
                        className={classes.apply}
                      >
                        Apply
                      </Button>
                      <Button
                        onClick={this.handleClose}
                        className={classes.cancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}

const styles = () => ({
  filterSection: {
    margin: '0 0.5rem',
  },
  filterbutton: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#383838',
    width: 120,
    margin: '0 0.5rem'
  },
  menuItem: {
    textTransform: 'capitalize',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    fontSize: '0.875rem',
    color: '#383838',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 400,
  },
  filtercontainer: {
    width: 220,
    maxHeight: 480,
    overflowY: 'scroll',
    paddingBottom: '3rem',
  },
  optionsItem: {
    padding: '0.5rem',
    fontSize: '0.875rem',
    color: '#383838',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  checkbox: {
    marginTop: '0.3rem',
    padding: '4px 8px',
    marginRight: '1rem',
  },
  collapse: {
    outline: 'none',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '0.5rem 1rem',
    borderTop: '1px solid #f3f3f3',
    position: 'fixed',
    bottom: 0,
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: '#fff',
  },
  apply: {
    color: '#fff',
    backgroundColor: '#253bde',
    width: 80,
    borderRadius: 25,
    fontSize: '0.8rem',
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: '#027be4',
    },
  },
  cancel: {
    color: '#6e6e6e',
    backgroundColor: '#fff',
    width: 80,
    borderRadius: 25,
    fontSize: '0.8rem',
    padding: '4px 8px',
  },
})

export default withStyles(styles)(Filter)
