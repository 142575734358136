import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { styles } from './Archive-styles'
import Pagination from '../../components/Archive/Pagination'
import Filter from '../../components/Archive/Filter'
import AlgoItem from '../../components/Archive/AlgoItem'
import Search from '../../components/Archive/Search'
import { avatarColors } from '../../UI/style_consts'

class Archive extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page_number: 1,
      page_size: 10,
      sort: {
        timestamp: 'asc'
      }
    }
    this.headers = new Headers()
    this.headers.append('Content-Type', 'application/json')
    this.getArchives()
  }

  getArchives = (search = {}) => {
    const {
      page_number,
      page_size,
      sort,
    } = this.state
    const { getArchives } = this.props
    const params = {
      page_size,
      page_number,
      sort,
      search
    }
    getArchives(params, this.headers)
  }

  pageChangeHandler = (diff) => {
    const { total_pages } = this.props
    this.setState((prevState) => {
      let newPage = prevState.page + diff
      if (newPage >= total_pages) {
        newPage = total_pages - 1
      } else if (newPage < 0) {
        newPage = 0
      }
      return { page: newPage }
    }, () => this.getArchives())
  }

  render() {
    const {
      archives,
      total_pages,
      classes
    } = this.props
    const { page_number, } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Pagination
            totalPages={total_pages}
            currentPage={page_number}
            jumpToPage={diff => this.pageChangeHandler(diff)}
          />
          <div className={classes.right_header}>
            <Search />
            <Filter
              filterChanged={selection => this.getArchives(selection)}
            />
          </div>
        </div>
        {archives.map((archive, i) => {
          const colorConfig = avatarColors[i % 7]
          return (
            <AlgoItem
              key={archive.algo_uuid}
              archive={archive}
              colorConfig={colorConfig}
            />
          )
        })}
      </div>
    )
  }
}

export default withStyles(styles)(Archive)
