export const styles = (theme) => {
  return {
    heading: {
      textAlign: 'center',
      fontSize: '2.5rem',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      color: theme.textDark_2,
    },
    title: {
      textAlign: 'center',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      color: theme.textDark_2,
    },
    desc: {
      textAlign: 'center',
      fontSize: '1.25rem',
      fontWeight: 400,
      letterSpacing: '0.5px',
      color: theme.textDark,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 400,
      color: theme.textDark,
      letterSpacing: '0.5px',
      textAlign: 'center',
      lineHeight: '1.5',
    },
    h2: {
      textAlign: 'center',
      fontSize: '2.5rem',
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      color: theme.textDark_2,
    },
    normal: {
      textAlign: 'center',
      fontSize: '0.8rem',
      fontWeight: 400,
      letterSpacing: '0.5px',
      color: theme.textDark_2,
    },
    '@media screen and (max-width: 600px)': {
      title: { fontSize: '1rem' },
      h6: { fontSize: '0.75rem' },
    },
  }
}
