import React from 'react'

const Search = ({
  width = 32,
  height = 32,
  color = '#666666',
}) => {
  return (
    <svg viewBox="0 0 64 64" width={width} height={height}>
      <g>
        <path
          fill={color}
          d="M62.1,58.3L48.5,44.7c3.7-4.5,5.8-10.3,5.8-16.6c0-14.6-11.9-26.5-26.5-26.5C13.2,1.6,1.3,13.5,1.3,28.1
          s11.9,26.5,26.5,26.5c6.9,0,13.2-2.7,18-7l13.4,13.4c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6C62.8,60.3,62.8,59.1,62.1,58.3z
           M27.8,50.7c-12.4,0-22.5-10.1-22.5-22.5c0-12.4,10.1-22.5,22.5-22.5s22.5,10.1,22.5,22.5C50.4,40.6,40.2,50.7,27.8,50.7z"
        />
      </g>
    </svg>
  )
}

export default Search
