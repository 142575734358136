import React from 'react'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core'
import { styles } from './Typography-styles'

const Typography = ({
  variant = 'heading',
  children,
  extraStyles,
  classes,
}) => {
  return (
    <div className={classnames(classes[variant], extraStyles)}>
      {children}
    </div>
  )
}

export default withStyles(styles)(Typography)
