import {
  UPDATE_NOTIFICATIONS,
  RESET_NOTIFICATION_COUNT,
  FETCH_ALGOS_INIT,
  FETCH_ALGOS_SUCCESS,
  FETCH_ALGOS_FAILURE,
  ACTIVE_SCREEN,
  TOGGLE_TERMS_PAGE,
  CLEAR_SEARCH_RESP,
  REFRESH_ALGO,
  FETCH_PROFILE_CAROUSEL,
  FETCH_PROFILE_CAROUSEL_SUCCESS,
  FETCH_PROFILE_CAROUSEL_FAILURE,
  TOGGLE_ROOT_MODAL,
  UPDATE_WEBSITE_CONFIG,
  TOGGLE_AUTH_GREETING,
  TOGGLER_TOUR,
} from './actionTypes'

import { setLocalStorage } from '../../utils/common'
// import { PREFERENCES } from '../Profile/profileUtils'

// const siteConfig = getLocalStorage(PREFERENCES.SITE_CONFIG)

const initialState = {
  notifications: [],
  algoList: [],
  isFetchingAlgoList: false,
  algoListError: false,
  active: 'Dashboard',
  terms_uri: false,
  terms_uri_heading: '',
  isFetchingProfileCarousel: true,
  profileCarousel: [],
  profileCarouselError: false,
  profileCarouselErrorMsg: '',
  rootModalName: '',
  showRootModal: false,
  stateChanged: {},
  greetingModal: '',
  routeAfterActivation: '',

  // websiteConfig: siteConfig ? JSON.parse(siteConfig) : {},
  startTour: false,
}

const serverTicks = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS: {
      const { notification } = action
      return {
        ...state,
        notifications: [notification],
      }
    }

    case RESET_NOTIFICATION_COUNT: {
      return {
        ...state,
        notifications: [],
      }
    }

    case FETCH_ALGOS_INIT:
      return {
        ...state,
        algoListError: false,
        isFetchingAlgoList: true,
      }
    // same keys used for scan result
    case FETCH_ALGOS_SUCCESS: {
      const { data: { data } } = action
      return {
        ...state,
        isFetchingAlgoList: false,
        algoList: data,
      }
    }

    case FETCH_ALGOS_FAILURE: {
      return {
        ...state,
        algoListError: true,
        isFetchingAlgoList: true,
      }
    }

    case ACTIVE_SCREEN: {
      const { active } = action
      return {
        ...state,
        active,
      }
    }

    case TOGGLE_TERMS_PAGE: {
      const { terms_uri, heading } = action
      return {
        ...state,
        terms_uri,
        terms_uri_heading: heading,
      }
    }

    case CLEAR_SEARCH_RESP:
      return {
        ...state,
        algoList: [],
        isFetchingAlgoList: false,
        algoListError: false,
      }

    case REFRESH_ALGO: {
      const { field, value } = action
      return {
        ...state,
        stateChanged: {
          ...state.stateChanged,
          [field]: value,
        },
      }
    }

    case FETCH_PROFILE_CAROUSEL:
      return {
        ...state,
        isFetchingProfileCarousel: true,
        profileCarousel: [],
        profileCarouselError: false,
        profileCarouselErrorMsg: '',
      }

    case FETCH_PROFILE_CAROUSEL_SUCCESS:
      return {
        ...state,
        isFetchingProfileCarousel: false,
        profileCarousel: action.data.profile_carousel,
      }

    case FETCH_PROFILE_CAROUSEL_FAILURE:
      return {
        ...state,
        isFetchingProfileCarousel: false,
        profileCarouselError: true,
        profileCarouselErrorMsg: action.error,
      }

    case TOGGLE_ROOT_MODAL:
      return {
        ...state,
        rootModalName: action.modalName,
        showRootModal: action.showModal,
      }

    case UPDATE_WEBSITE_CONFIG: {
      const { field, config } = action
      let websiteConfig = {}
      if (field === null) {
        websiteConfig = config
      } else {
        websiteConfig = {
          ...state.websiteConfig,
          [field]: config,
        }
        setLocalStorage({ websiteConfig }, true)
      }
      return {
        ...state,
        websiteConfig,
      }
    }

    case TOGGLE_AUTH_GREETING: {
      const { modalType, routeAfterActivation } = action
      return {
        ...state,
        greetingModal: modalType,
        routeAfterActivation: routeAfterActivation || state.routeAfterActivation,
      }
    }

    case TOGGLER_TOUR: {
      return {
        ...state,
        startTour: action.params,
      }
    }

    default:
      return state
  }
}

export default serverTicks
