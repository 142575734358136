import React from 'react'

const CheckedBox = ({
  width = 32,
  height = 32,
  color = '#0088ff',
}) => {
  return (
    <svg viewBox="0 0 64 64" width={width} height={height}>
      <path
        fill={color}
        d="M108.89,0H69.27a3,3,0,0,0-3,3V42.66a3,3,0,0,0,3,3h39.55a3.08,3.08,0,0,0,3.07-3V3A3,3,0,0,0,108.89,0Zm-4.48,14.86L86.21,34.73a2.54,2.54,0,0,1-1.87.82H84.27a2.94,2.94,0,0,1-1.87-.75l-8.65-8.88a2.68,2.68,0,0,1,0-3.73,2.59,2.59,0,0,1,3.66,0l6.79,6.86,16.34-17.83a2.59,2.59,0,0,1,3.66-.15A2.71,2.71,0,0,1,104.41,14.88Z"
        transform="translate(-66.27 -0.02)"
      />
    </svg>
  )
}

export default CheckedBox
