export const styles = (theme) => {
  return {
    item: {
      padding: '0.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    avataar: {
      width: '36px',
      height: '36px',
      borderRadius: '18px',
      margin: '0.2rem 0.5rem 0.2rem 0',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      display: 'flex',
      fontSize: '0.875rem'
    },
    name_type: {
      width: '120px',
      textAlign: 'center',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '0.25rem'
    },
    algo_name: {
      color: theme.textDark_2,
      fontSize: '0.8rem',
      fontWeight: '500',
      marginBottom: '0.5rem'
    },
    BULLISH: {
      padding: '4px 8px',
      color: theme.blueText,
      backgroundColor: theme.blueBg,
      fontSize: '0.55rem',
      fontWeight: '400',
      letterSpacing: '0.5px'
    },
    BEARISH: {
      padding: '4px 8px',
      color: theme.orangeText,
      backgroundColor: theme.orangeBg,
      fontSize: '0.55rem',
      fontWeight: '400',
      letterSpacing: '0.5px'
    },
    entry_exit: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '360px'
    },
    ee: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '0.5rem'
    },
    eetext: {
      fontSize: '0.8rem',
      color: theme.textDark_2,
      letterSpacing: '0.5px',
      fontWeight: '400',
      marginLeft: '0.25rem'
    },
    entry_exit_text: {
      margin: '0 0.5rem',
      flex: '1 1',
      color: theme.textDark_2,
      fontSize: '0.8125rem',
      fontWeight: '400',
      alignSelf: 'flex-start',
      letterSpacing: '0.5px',
      lineHeight: '1.46429em'
    },
    img: { margin: '0.2rem', width: '20px', alignSelf: 'flex-start' },
    winloss: {
      display: 'flex',
      width: '120px',
      height: '6px',
      padding: '0 0.5rem',
      boxSizing: 'border-box'
    },
    pnl: {
      boxSizing: 'border-box',
      paddingRight: '0.75rem',
      width: '90px',
      color: '#06d006',
      fontSize: '0.8rem',
      letterSpacing: '0.5px',
      fontWeight: '400',
      textAlign: 'right'
    },
    maxdd: {
      boxSizing: 'border-box',
      paddingRight: '0.75rem',
      width: '90px',
      color: theme.textDark_2,
      fontSize: '0.8rem',
      letterSpacing: '0.5px',
      fontWeight: '400',
      textAlign: 'right'
    }
  }
}
