// SVG
import LogoDark from '../assets/streak-logo/logo-tm-dark.png'
import BAR from '../assets/png/technicals/bar.svg'
import BAR_MA from '../assets/png/technicals/bar_ma.svg'
import BAR_OSCILlATOR from '../assets/png/technicals/bar_oscillator.svg'
import XS_BAR from '../assets/png/technicals/bar_small.svg'
import BAR_RESISTANCE from '../assets/png/technicals/bar_resistance.svg'
import XS_BAR_RESISTANCE from '../assets/png/technicals/bar_resistance_small.svg'
import XS_POINTER from '../assets/png/technicals/pointer_small.svg'
import POINTER from '../assets/png/technicals/pointer.svg'
// import Pointer from '../assets/svgs/pointer.svg'
import XS_POINTER_DARK from '../assets/png/technicals/xs_pointer_dark.svg'
import POINTER_DARK from '../assets/png/technicals/pointer_dark.svg'
import CROSSOVER from '../assets/svgs/discoverScans/crossover.svg'
import INTRADAY_BEARISH from '../assets/svgs/discoverScans/intraday_bearish.svg'
import BEARISH from '../assets/svgs/discoverScans/bearish.svg'
import BULLISH from '../assets/svgs/discoverScans/bullish.svg'
import INTRADAY_BULLISH from '../assets/svgs/discoverScans/intraday_bullish.svg'
import RANGE_BREAKOUT from '../assets/svgs/discoverScans/range_breakout.svg'
import TOP_GAINERS from '../assets/svgs/discoverScans/top_gainers.svg'
import TOP_LOSERS from '../assets/svgs/discoverScans/top_losers.svg'
import WEBINAR from '../assets/svgs/discoverScans/webinar.svg'
import CANDLE_PATTERNS from '../assets/svgs/discoverScans/candle_patterns.svg'
import PointerResistance from '../assets/png/technicals/pointer_resistance.svg'
// Exchange Icons
import CDS from '../assets/exchanges/CDS-FUT.svg'
import MCX from '../assets/exchanges/MCX.svg'
import NFOFUT from '../assets/exchanges/NFO-FUT.svg'
import NSE from '../assets/exchanges/NSE.svg'
import BSE from '../assets/exchanges/BSE.svg'
import DYC_DEFAULT from '../assets/exchanges/dyc_default.svg'

export default {
  LogoDark,
  POINTER,
  XS_POINTER_DARK,
  POINTER_DARK,
  // Pointer,
  PointerResistance,
  BAR,
  XS_BAR,
  BAR_RESISTANCE,
  XS_BAR_RESISTANCE,
  BAR_MA,
  BAR_OSCILlATOR,
  XS_POINTER,

  CANDLE_PATTERNS,
  WEBINAR,
  BULLISH,
  BEARISH,
  INTRADAY_BULLISH,
  INTRADAY_BEARISH,
  RANGE_BREAKOUT,
  CROSSOVER,
  TOP_GAINERS,
  TOP_LOSERS,

  CDS,
  MCX,
  NFOFUT,
  NSE,
  BSE,
  DYC_DEFAULT,
}
