import React from 'react'
import PropTypes from 'prop-types'

import Marquee from './Marquee'
import CustomText from './CustomText'

const styles = {
  segSym: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  segSymCol: {
    display: 'inline-grid',
  },
  sym: {},
  seg: {
    textTransform: 'uppercase',
    marginTop: 6,
  },
}

const SegSym = React.memo(({
  seg_sym,
  style,
  segStyles,
  symStyles,
  column,
  segChild,
  splitter,
  ellipsis,
  showTooltip,
  marqueStyle,
  symWeight,
}) => {
  const segSymArr = seg_sym.split(splitter)
  // for dynamic symbol
  const [segment] = segSymArr
  segSymArr.shift()
  const symbol = segSymArr.join('_')
  const view = (
    <div style={{ ...styles[column ? 'segSymCol' : 'segSym'], ...style }}>
      <CustomText weight={symWeight} style={symStyles} ellipsis={ellipsis}>
        {symbol}
        {segChild}
      </CustomText>
      <CustomText
        size="small"
        color="textSecondary"
        ellipsis={ellipsis}
        style={{ marginLeft: column ? 0 : 4, ...segStyles }}
      >
        {segment}
      </CustomText>
    </div>
  )

  if (showTooltip) {
    return (
      <Marquee showMarquee={false} tooltipText={`${symbol} ${segment}`} changes={seg_sym} style={marqueStyle}>
        {view}
      </Marquee>
    )
  }
  return view
})

export default SegSym

SegSym.defaultProps = {
  style: {},
  segStyles: {},
  symStyles: {},
  column: false,
  segChild: '',
  splitter: '_',
  ellipsis: true,
  showTooltip: false,
  symWeight: 'weight',
}

SegSym.propTypes = {
  seg_sym: PropTypes.string.isRequired,
  column: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  segStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  symStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  segChild: PropTypes.node,
  symWeight: PropTypes.string,
  splitter: PropTypes.string,
  ellipsis: PropTypes.bool,
  showTooltip: PropTypes.bool,
}
