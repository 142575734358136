import {
  GET_ANALYSIS_INIT,
  GET_ANALYSIS_SUCCESS,
  GET_ANALYSIS_FAILURE,
  GET_SAMPLES_INIT,
  GET_SAMPLES_SUCCESS,
  GET_SAMPLES_FAILURE,
  POPULAR_SCREENS_INIT,
  POPULAR_SCREENS_SUCCESS,
  POPULAR_SCREENS_FAILURE,
  FETCH_RESISTANCE,
  FETCH_RESISTANCE_SUCCESS,
  FETCH_RESISTANCE_FAILURE,
} from './actionTypes'
import { formatAnalysis } from '../../utils/common'

const initialState = {
  isFetching: false,
  analysis: {},
  analysisError: false,
  analysisErrorMsg: '',
  isFetchingSamples: false,
  samples: [],
  samplesError: false,
  samplesErrorMsg: '',
  popularScreens: {},
  isFetchingPopular: false,
  popularScreensError: false,
  popularScreenErrorMsg: '',
  // support and resistance
  resistance: {},
  isFetchingResistance: false,
  resistanceError: false,
  resistanceErrorMsg: '',
}

const info = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYSIS_INIT:
      return {
        ...state,
        isFetching: true,
      }

    case GET_ANALYSIS_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetching: false,
        analysis: formatAnalysis(data)
      }
    }

    case GET_ANALYSIS_FAILURE:
      return {
        ...state,
        isFetching: false,
        analysisError: true,
        analysis: {},
      }

    case GET_SAMPLES_INIT:
      return {
        ...state,
        isFetchingSamples: true,
        samples: [],
        samplesError: false,
      }

    case GET_SAMPLES_SUCCESS: {
      const { data } = action
      return {
        ...state,
        isFetchingSamples: false,
        samples: data,
      }
    }

    case GET_SAMPLES_FAILURE:
      return {
        ...state,
        isFetchingSamples: false,
        samplesError: true,
        samplesErrorMsg: action.error
      }

    case POPULAR_SCREENS_INIT:
      return {
        ...state,
        isFetchingPopular: true,
        popularScreensError: false,
        popularScreenErrorMsg: '',
      }

    case POPULAR_SCREENS_SUCCESS: {
      const { data } = action
      const categorizedScreens = {}
      for (let i = 0; i < data.length; i++) {
        const itm = data[i]
        const lastTag = itm.tags[itm.tags.length - 1]
        if (categorizedScreens[lastTag]) {
          categorizedScreens[lastTag].push(itm)
        } else {
          categorizedScreens[lastTag] = [itm]
        }
      }
      return {
        ...state,
        popularScreens: categorizedScreens,
        isFetchingPopular: false,
      }
    }

    case POPULAR_SCREENS_FAILURE:
      return {
        ...state,
        isFetchingPopular: false,
        popularScreensError: true,
        popularScreenErrorMsg: action.error,
      }

    case FETCH_RESISTANCE:
      return {
        ...state,
        isFetchingResistance: true,
      }

    case FETCH_RESISTANCE_SUCCESS: {
      const { data, stockList } = action
      let modAnalysis = { ...state.analysis }
      stockList.forEach((item) => {
        modAnalysis = {
          ...modAnalysis,
          [item]: data[item] || { noData: true },
        }
      })
      return {
        ...state,
        resistance: modAnalysis,
        isFetchingResistance: false,
      }
    }

    case FETCH_RESISTANCE_FAILURE:
      return {
        ...state,
        isFetchingResistance: false,
        resistanceError: true,
        resistanceErrorMsg: action.error,
      }

    default:
      return state
  }
}

export default info
