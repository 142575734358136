import { call, put, takeLatest, } from 'redux-saga/effects'
import {
  GET_ANALYSIS_INIT,
  GET_SAMPLES_INIT,
  POPULAR_SCREENS_INIT,
  FETCH_RESISTANCE
} from './actionTypes'
import {
  getAnalysisSuccess,
  getAnalysisFailure,
  getSamplesSuccess,
  getSamplesFailure,
  popularScreensSuccess,
  popularScreensFailure,
  fetchResistanceSuccess,
  fetchResistanceFailure,
} from './actions'
import { baseUrl, endpoints } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'

function* analysis(action) {
  try {
    const { params, headers } = action.payload
    const url = `${baseUrl}${endpoints.analysis}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status) {
      yield put(getAnalysisSuccess(data))
    } else {
      yield put(getAnalysisFailure(data.error_msg))
    }
  } catch (err) {
    yield put(getAnalysisFailure('Unable to get analysis'))
  }
}

export function* analysisSaga() {
  yield takeLatest(GET_ANALYSIS_INIT, analysis)
}

function* samples(action) {
  try {
    const { params, headers } = action.payload
    const url = `${endpoints.samples}`
    const data = yield call(getRequest, url, params, headers)
    if (!data.error && data.status === 'success') {
      yield put(getSamplesSuccess(data.samples))
    } else {
      yield put(getSamplesFailure(data.error_msg))
    }
  } catch (err) {
    yield put(getSamplesFailure('Unable to get samples'))
  }
}

export function* samplesSaga() {
  yield takeLatest(GET_SAMPLES_INIT, samples)
}

function* popularScreens(action) {
  try {
    const { params, headers } = action.payload
    const url = `${endpoints.get_screens}`
    const data = yield call(postRequest, url, params, headers, false)
    if (!data.error && data.data) {
      yield put(popularScreensSuccess(data.data.results))
    } else {
      yield put(popularScreensFailure(data.error_msg || 'Error getting response'))
    }
  } catch (err) {
    yield put(popularScreensFailure('Error getting response'))
  }
}

export function* popularScreensSaga() {
  yield takeLatest(POPULAR_SCREENS_INIT, popularScreens)
}

function* fetchResistance(action) {
  try {
    const { params } = action
    const url = `${baseUrl}${endpoints.resistance_analysis}`
    const data = yield call(postRequest, url, JSON.stringify(params), { 'Content-Type': 'application/json' }, false)
    if (data.data && typeof data.data === 'object' && Object.keys(data.data).length) {
      if (Object.keys(data.data).length) {
        // const token_keys = technicalPairs(Object.keys(data.data))
        // yield put(subscribe({ token_keys }))
        yield put(fetchResistanceSuccess(data.data, params.stocks))
      } else {
        yield put(fetchResistanceFailure('Unable to get analysis'))
      }
    } else {
      yield put(fetchResistanceFailure(data.error_msg))
    }
  } catch (err) {
    yield put(fetchResistanceFailure('Unable to get analysis'))
  }
}

export function* fetchReistanceSaga() {
  yield takeLatest(FETCH_RESISTANCE, fetchResistance)
}
