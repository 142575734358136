import React from 'react'
import { withStyles } from '@material-ui/core'
import { styles } from './styles/AlgoItem-styles'
import { algoSummary } from '../../utils/common'
import entry from '../../assets/entry.svg'
import exit from '../../assets/exit.svg'

const AlgoItem = ({
  archive,
  colorConfig = {},
  classes
}) => {
  const {
    algo_name,
    action_type,
    pnl = 0.0,
    maxdd = 0.0,
  } = archive
  const summary = algoSummary(archive)
  const type = action_type === 'BUY' ? 'BULLISH' : 'BEARISH'
  return (
    <div className={classes.item}>
      <div
        style={{
          backgroundColor: colorConfig.color,
          color: colorConfig.text,
          boxShadow: colorConfig.shadow,
        }}
        className={classes.avataar}
      >
        {algo_name[0]}
      </div>
      <div className={classes.name_type}>
        <div className={classes.algo_name}>
          {algo_name}
        </div>
        <div className={classes[type]}>
          {type}
        </div>
      </div>
      <div className={classes.entry_exit}>
        <div className={classes.ee}>
          <img src={entry} className={classes.img} alt="entry" />
          <div className={classes.eetext}>Entry</div>
        </div>
        <div className={classes.entry_exit_text}>
          {summary.entryStr}
        </div>
      </div>
      <div className={classes.entry_exit}>
        <div className={classes.row}>
          <div className={classes.ee}>
            <img src={exit} className={classes.img} alt="exit" />
            <div className={classes.eetext}>Exit</div>
          </div>
        </div>
        <div className={classes.entry_exit_text}>
          {summary.exitStr}
        </div>
      </div>
      <div className={classes.pnl}>
        {pnl.toFixed(2)}
      </div>
      <div className={classes.winloss}>
        <div style={{ flex: 2, backgroundColor: '#06d092' }} />
        <div style={{ flex: 1, backgroundColor: '#ff4258' }} />
      </div>
      <div className={classes.maxdd}>
        {`${maxdd.toFixed(2)} %`}
      </div>
    </div>
  )
}

export default withStyles(styles)(AlgoItem)
