import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import { connect } from 'react-redux'

import CustomText from '../../../UI/CustomText'
import TechnicalGraph from '../../../UI/TechnicalGraph'
import Icon from '../../../UI/Icon'

import {
  DIMENSIONS, SPACING, theme,
  ICONS,
} from '../../../Theme'
import CustomTooltip from '../../../UI/CustomTooltip'

const TechnicalAggregate = ({
  analysis,
  isFetchingAnalysis,
  analysisError,
}) => {
  const styles = stylesheet()
  const data = analysis
  const isDark = false
  let renderOsc = null
  let renderMovingAvg = null
  if (isFetchingAnalysis && !data) {
    // renderOsc = <TechnicalsAggregateLoader styles={styles} />
    renderMovingAvg = renderOsc
  } else if (data && data.noData) {
    renderOsc = <CustomText className={styles.noDataText}>No data available</CustomText>
    renderMovingAvg = renderOsc
  } else if (analysisError || !data) {
    renderOsc = <CustomText className={styles.errorText}>Not able to fetch data</CustomText>
    renderMovingAvg = renderOsc
  } else {
    const {
      mabuy = 0.0,
      masell = 0.0,
      maneutral = 0.0,
      obuy = 0.0,
      osell = 0.0,
      oneutral = 0.0,
      rsi,
      rec_rsi,
      adx,
      rec_adx,
      awesome_oscillator,
      rec_ao,
      cci,
      rec_cci,
      macd,
      rec_macd,
      momentum,
      rec_mom,
      stochastic_k,
      rec_stochastic_k,
      stoch_rsi_fast,
      rec_stochastic_rsi_fast,
      rec_ichimoku,
      ult_osc,
      rec_ult_osc,
      willR,
      rec_willR,
      ema5,
      sma5,
      ema10,
      sma10,
      ema20,
      sma20,
      ema30,
      sma30,
      ema50,
      sma50,
      ema100,
      sma100,
      ema200,
      sma200,
      close,
      vwma,
      hma,
      ichimoku,
    } = analysis
    const item = (text, value = 0.0, action, cls) => {
      let a_t = 'N'
      if (cls === undefined) {
        if (action === 1) {
          a_t = 'B'
        } else if (action === -1) {
          a_t = 'S'
        }
      }
      if (cls !== undefined) {
        if (cls > value && value !== 0) {
          a_t = 'B'
        } else if (cls < value && value !== 0) {
          a_t = 'S'
        } else {
          a_t = 'N'
        }
      }
      const extraStyles = isDark ? { color: theme.text } : {}
      return (
        <div className={styles.item}>
          <CustomText center className={`${styles.aType} ${styles[a_t]}`} style={extraStyles} size="tiny">{a_t}</CustomText>
          <CustomText className={styles.text} size="small" weight="regular">{text}</CustomText>
          <CustomText className={styles.value}>{value.toFixed(2)}</CustomText>
        </div>
      )
    }
    const calcPos = (buy, sell, neutral) => {
      const neg_sell = -1 * sell
      const pct = (buy + neg_sell) / (buy + sell + neutral)
      const leftPosition = Math.abs((pct * 50) + 50)

      // if (pct > 0.5) {
      //   leftPosition = 96
      // } else if (pct > 0 && pct <= 0.5) {
      //   leftPosition = 68
      // } else if (pct < -0.5) {
      //   leftPosition = 4
      // } else if (pct < 0 && pct >= -0.5) {
      //   leftPosition = 32
      // }
      return leftPosition
    }

    renderOsc = (
      <>
        <div className={styles.indiL}>
          <div className={styles.chart}>
            <TechnicalGraph
              leftPosition={calcPos(obuy, osell, oneutral)}
              graphViewStyles={styles.graphView}
              showBuySellInfo
              sell={osell}
              neutral={oneutral}
              buy={obuy}
              isDark={isDark}
              barType="OSCILLATOR"
            />
          </div>
          {item('RSI (14)', rsi, rec_rsi)}
          {item('Stoch.%K (14, 3, 3)', stochastic_k, rec_stochastic_k)}
          {item('CCI (20)', cci, rec_cci)}
          {item('ADI (14)', adx, rec_adx)}
          {item('Awesome Osc.', awesome_oscillator, rec_ao)}
          {item('Momentum (10)', momentum, rec_mom)}
          {item('Macd (12, 26, 9)', macd, rec_macd)}
          {item('Stoch. RSI Fast (3, 3, 14, 14)', stoch_rsi_fast, rec_stochastic_rsi_fast)}
          {item('Williams %Range (14)', willR, rec_willR)}
          {item('Ultimate Osc. (7, 14, 28)', ult_osc, rec_ult_osc)}
        </div>
      </>
    )
    // const posMa = calcPos(maBuy, maSell, maNeutral)
    renderMovingAvg = (
      <>
        <div className={styles.indi}>
          <div className={styles.chart}>
            <TechnicalGraph
              leftPosition={calcPos(mabuy, masell, maneutral)}
              graphViewStyles={styles.graphView}
              showBuySellInfo
              sell={masell}
              neutral={maneutral}
              buy={mabuy}
              isDark={isDark}
              barType="MA"
            />
          </div>
          {item('EMA (5)', ema5, '', close)}
          {item('SMA (5)', sma5, '', close)}
          {item('EMA (10)', ema10, '', close)}
          {item('SMA (10)', sma10, '', close)}
          {item('EMA (20)', ema20, '', close)}
          {item('SMA (20)', sma20, '', close)}
          {item('EMA (30)', ema30, '', close)}
          {item('SMA (30)', sma30, '', close)}
          {item('EMA (50)', ema50, '', close)}
          {item('SMA (50)', sma50, '', close)}
          {item('EMA (100)', ema100, '', close)}
          {item('SMA (100)', sma100, '', close)}
          {item('EMA (200)', ema200, '', close)}
          {item('SMA (200)', sma200, '', close)}
          {item('Ichimoku Base line (9, 26, 52, 26)', ichimoku, rec_ichimoku)}
          {item('Volume Weighted MA (20)', vwma, '', close)}
          {item('Hull MA (9)', hma, '', close)}
        </div>
      </>
    )
  }

  const renderPopup = (type) => {
    return (
      <div className={styles.popupContainer}>
        <CustomText>
          {type === 'osc'
            ? ' They form a majority of the leading technical indicators and they oscillate between a local minimum & maximum. The interpretation varies based on the position of the oscillator on the chart.'
            : ' This indicator helps greatly in reducing the random ups and downs and filtering out the unwanted noise in the price charts. It shows you a clear trend by smoothening out the prices and following the ‘average value moving.’ There are a variety of Moving Averages.'}
        </CustomText>
      </div>
    )
  }
  const renderPopupOsc = () => {
    return renderPopup('osc')
  }

  return (
    <div className={styles.row}>
      <div className={`${styles.rowItem}`}>
        {isFetchingAnalysis ? (
          // <PlaceHolderLoader className={styles.loader} />
          <div />
        ) : (
          <div className={styles.header}>
            <CustomText
              className={styles.title}
              weight="semi_bold"
            >
              Moving averages

            </CustomText>
            <CustomTooltip
              show
              tooltipProps={{ }}
              contentRenderer={renderPopup}
              placement="top"
              tooltipContainerStyles={styles.tooltipContainer}
              interactive
            >
              <div
                className={styles.info}
              >
                <Icon
                  name={ICONS.INFO}
                  className={styles.infoIcon}
                  color={theme.textSecondary}
                  size={16}
                />
              </div>
            </CustomTooltip>
          </div>
        )}
        {renderMovingAvg}
      </div>
      <div className={styles.rowItem}>
        {isFetchingAnalysis ? (
          // <PlaceHolderLoader className={styles.loader} />
          <div />
        ) : (
          <div className={styles.header}>
            <CustomText className={styles.title} weight="semi_bold">Oscillators</CustomText>
            <CustomTooltip
              show
              tooltipProps={{ }}
              contentRenderer={renderPopupOsc}
              placement="top"
              tooltipContainerStyles={styles.tooltipContainer}
              interactive
            >
              <div className={styles.info}>
                <Icon
                  name={ICONS.INFO}
                  className={styles.infoIcon}
                  color={theme.textSecondary}
                  size={16}
                />
              </div>
            </CustomTooltip>
          </div>
        )}
        {renderOsc}
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  row: {
    display: 'flex',
    paddingTop: 142,
    // minHeight: 335,
    // height: 'calc(100vh - 10px)',
    // overflow: 'auto',
  },
  loader: {
    height: 12,
    maxWidth: 100,
  },
  rowItem: {
    backgroundColor: theme.bgPrimary,
    borderRadius: 6,
    // minHeight: 100,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_16}`,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    flex: 0.5,
    '&:first-child': {
      marginRight: SPACING.SPACE_20,
    },

    '&:hover $infoIcon': {
      fill: theme.linkColor,
    },
  },
  infoIcon: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
  },
  indiL: {
    backgroundColor: theme.bgPrimary,
    borderRadius: 4,
    width: '80%',
    margin: 'auto',
  },
  indi: {
    backgroundColor: theme.bgPrimary,
    borderRadius: 4,
    width: '80%',
    margin: 'auto',
  },
  chart: {
    margin: `${SPACING.SPACE_20} 0`,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  graphView: {
    width: DIMENSIONS.WIDTH - 40,
  },
  item: {
    display: 'flex',
    marginBottom: SPACING.SPACE_20,
    alignItems: 'center',
  },
  text: {
    flex: 1,
    marginLeft: SPACING.SPACE_8,
  },
  value: {},
  aType: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  N: {
    width: SPACING.SPACE_20,
    textAlignVertical: 'center',
    height: SPACING.SPACE_20,
    alignSelf: 'center',
    borderRadius: 2,
    color: theme.text,
    backgroundColor: theme.greyLightBg,
  },
  B: {
    width: SPACING.SPACE_20,
    textAlignVertical: 'center',
    height: SPACING.SPACE_20,
    alignSelf: 'center',
    borderRadius: 2,
    color: `${theme.linkColor} !important`,
    backgroundColor: theme.blueLightBg,
  },
  S: {
    width: SPACING.SPACE_20,
    textAlignVertical: 'center',
    height: SPACING.SPACE_20,
    alignSelf: 'center',
    borderRadius: 2,
    color: `${theme.red} !important`,
    backgroundColor: theme.redLightBg,
  },

  buySellCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${SPACING.SPACE_20} 0 0`,
  },
  countCell: {
    textAlign: 'center',
    flex: 0.25,
    height: 50,
  },
  tooltipContainer: {
    backgroundColor: theme.bgPrimary,
  },
  popupContainer: {
    padding: SPACING.SPACE_20,
    borderRadius: 8,
    minWidth: 300,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
  },
  '@media only screen and (max-width: 786px)': {
    row: {
      flexDirection: 'row',
    },
    rowItem: {
      flex: 1,
      '&:first-child': {
        margin: `0 0 ${SPACING.SPACE_20}`,
      },
    },
  },
  '@media only screen and (max-width: 600px)': {
    row: {
      marginTop: 0,
      paddingTop: 0,
      // height: 'calc(100vh - 200px)',
      overflow: 'auto',
      flexDirection: 'column',
    },
  },
})

const mapStateToProps = () => {
  return {
  }
}

export default connect(mapStateToProps)(TechnicalAggregate)
