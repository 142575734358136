import { connect } from 'react-redux'

import {
  // updateNotifications,
  toggleTerms,
  pollUser,
  toggleRootModal,
} from './actions'
// import { getUserDetails } from '../Auth/actions'
// import { shouldRequestDemo } from '../Account/actions'
// import { resetScreenerTreeFetch } from '../Screener/actions'
// import {
//   getNotifications,
// } from '../Notifications/actions'
import { updateTicks } from '../Instruments/actions'
// import { getDeployedCount } from '../Deployed/actions'

import Common from './Common'

const mapStateToProps = (state) => {
  return {
    ...state.common,
    // ...state.auth.loginResp,
    // user_details: state.auth.user_details,
    // version: state.auth.version,

    // launchParams: state.create.launchParams,

    // isFetchingNotifications: state.notification.isFetchingNotifications,
    // notificationsError: state.notification.notificationsError,
    // isRegisteringNotification: state.notification.isRegisteringNotification,
    // registerNotificationResp: state.notification.registerNotificationResp,
    // registerNotificationError: state.notification.registerNotificationError,
    // registerNotificationErrorMsg: state.notification.registerNotificationErrorMsg,

    //
    tokens: state.instruments.tokens,
    unSubList: state.instruments.unSubList,
    seg_sym_list: state.instruments.seg_sym_list,
    unSubscribe: state.instruments.unSubscribe,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTicks: ticks => dispatch(updateTicks(ticks)),
    // updateNotifications: notification => dispatch(updateNotifications(notification)),
    // getUserDetails: () => dispatch(getUserDetails()),
    toggleTerms: (terms_uri, heading) => dispatch(toggleTerms(terms_uri, heading)),
    pollUser: (params, headers) => dispatch(pollUser(params, headers)),
    // setLaunchParams: initialUrl => dispatch(setLaunchParams(initialUrl)),
    // shouldRequestDemo: (params, headers) => dispatch(shouldRequestDemo(params, headers)),
    // resetScreenerTreeFetch: () => dispatch(resetScreenerTreeFetch()),
    // registerNotification: (params, token) => dispatch(registerNotification(params, token)),
    // getNotifications: headers => dispatch(getNotifications(headers)),
    // getDeployedCount: headers => dispatch(getDeployedCount(headers)),
    toggleRootModal: (modalName, showModal) => dispatch(toggleRootModal(modalName, showModal)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Common)
