import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import CustomTooltip from './CustomTooltip'

// showTooltip: tooltip will be visible always
// otherwise it will only be visible if the text getting cut

const Marquee = React.memo(({
  children, props, changes = null, tooltipText, showMarquee, showTooltip = true, style = {},
}) => {
  const eleRef = useRef()
  const parentRef = useRef()
  const [show, updateShow] = useState(showTooltip && Boolean(tooltipText))
  useEffect(() => {
    let dimCalcTimer = null
    // as there are animation so start the calculation of elements
    // after the ui is painted fully
    dimCalcTimer = setTimeout(() => {
      if (eleRef.current) {
        const a = parentRef.current.offsetWidth
        const b = parentRef.current.scrollWidth
        // const animate = (x) => {
        //   eleRef.current.style.transform = `translate(${x}px, 0)`
        // }
        // eleRef.current.style.transition = `all ${animDuration}ms linear`
        // let oldx = 0
        if (a < b) {
          if (tooltipText) updateShow(true)
          if (showMarquee) {
            const animDuration = b * (40)
            eleRef.current.style.animationDuration = `${animDuration}ms`
            eleRef.current.style.animationDelay = '2000ms' // `${animDuration}ms`
            eleRef.current.style.animationTimingFunction = 'linear'
            eleRef.current.style.animationIterationCount = 'infinite'
            eleRef.current.style.animationName = 'marque_slide_left'
            eleRef.current.style.textAlign = 'left'
          }
        //   animate(0)
        //   oldx = oldx === 0 ? a - (b + 5) : 0
        //   animate(oldx)
        //   id = setInterval(() => {
        //     oldx = oldx === 0 ? a - (b + 5) : 0
        //     animate(oldx)
        //   }, animDuration + 1000)
        }
      }
    }, 400)
    return () => {
      if (eleRef.current) {
        eleRef.current.style.transform = 'translate(0, 0)'
        // eleRef.current.style.transition = 'unset'
      }
      updateShow(showTooltip && Boolean(tooltipText))
      if (dimCalcTimer) clearTimeout(dimCalcTimer)
    }
  }, [changes])
  return (
    <CustomTooltip
      show={show}
      tooltipProps={{
        text: tooltipText,
      }}
    >
      <div
        style={{
          whiteSpace: 'nowrap', width: '100%', overflow: 'hidden', ...style,
        }}
        {...props}
        ref={parentRef}
      >

        <div style={{ display: 'inline-block' }} ref={eleRef}>

          {children}
        </div>
      </div>
    </CustomTooltip>

  )
})

export default Marquee

Marquee.defaultProps = {
  changes: false,
  tooltipText: '',
  showMarquee: true,
}

Marquee.propTypes = {
  changes: PropTypes.any,
  tooltipText: PropTypes.string,
  showMarquee: PropTypes.bool,
}
