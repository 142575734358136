import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import { withStyles } from '@material-ui/core'
import './index.css'
import Info from './containers/Info/Info-redux'
import Archive from './containers/Archive/Archive-redux'
import { THEME_TYPE_MAP } from './utils/consts'
import { withTheme } from './Theme/ThemeProvider'
import { theme } from './Theme'
import { parseQuery } from './utils/common'
import Common from './containers/Common/Common-redux'

class App extends Component {
  constructor(props) {
    super(props)
    this.active = document.location.pathname.includes('archives') ? 'archives' : 'info'
  }

  componentDidMount() {
    const { toggleTheme } = this.props
    const { search } = window.location
    let query = {}
    if(search) {
      query = parseQuery(search)
    }
    // eslint-disable-next-line no-shadow
    const { theme } = query
    if(theme === 'dark') {
      toggleTheme(THEME_TYPE_MAP.DARK.value)
    } else {
      toggleTheme(THEME_TYPE_MAP.LIGHT.value)
    }

    if ('serviceWorker' in navigator) {
      // Check if a service worker is already registered
      navigator.serviceWorker.getRegistrations().then(registrations => {
        // If there is a registered service worker, deregister it
        if (registrations.length > 0) {
          registrations.forEach(registration => {
            registration.unregister().then(success => {
              if (success) {
                console.log('Service worker deregistered successfully')
              } else {
                console.error('Failed to deregister service worker')
              }
            }).catch(error => {
              console.error('Error while deregistering service worker:', error)
            })
          })
        } else {
          console.log('No service worker registered')
        }
      }).catch(error => {
        console.error('Error while checking service worker registrations:', error)
      })
    } else {
      console.log('Service workers are not supported')
    }
  }

  render() {
    const { themeValue, styles, location } = this.props
    document.body.style.background = theme.bgPrimary
    return (
      <div className={styles.app}>
        <Common isWebView location={location} />
        {this.active === 'info' ? <Info themeValue={themeValue} /> : <Archive themeValue={themeValue} />}
      </div>
    )
  }
}

const stylesheet = {
  app: {
    display: 'block',
    height: '100vh',
    overflow: 'auto',
    position: 'relative',
    overflowX: 'hidden',
  },
}

export default withRouter(withTheme(stylesheet)(App))
