import {
  GET_ARCHIVES_INIT,
  GET_ARCHIVES_SUCCESS,
  GET_ARCHIVES_FAILURE,
} from './actionTypes'

const initialState = {
  isFetching: false,
  archives: [],
  total_pages: 0,
  archivesError: false,
  archivesErrorMsg: '',
}

const archive = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARCHIVES_INIT:
      return {
        ...state,
        isFetching: true,
        archives: [],
        archivesError: false,
      }

    case GET_ARCHIVES_SUCCESS: {
      const { data: { results, total_pages, } } = action
      return {
        ...state,
        isFetching: false,
        archives: results,
        total_pages,
      }
    }

    case GET_ARCHIVES_FAILURE:
      return {
        ...state,
        isFetching: false,
        archivesError: false,
        archivesErrorMsg: action.error,
      }

    default:
      return state
  }
}

export default archive
