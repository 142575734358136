export const endpoints = {
  analysis: 'tech_analysis_ab/',
  samples: 'https://api.streak.tech/market_observer_samples/',
  archive: 'https://s.streak.tech/archives/search',
  get_screens: 'https://s.streak.tech/screeners/filter_search',
  resistance_analysis: 'sr_analysis_multi/',
  profile_carousel: 'profile_carousel/',

}

export const baseUrl = 'https://mo.streak.tech/api/'

export const symbolIconsUrl = 'https://streak-in-public-static.s3.ap-south-1.amazonaws.com/'
export const notifUrl = 'https://notif.streak.tech/notification'

export const pollUrl = 'https://notif.streak.tech/handle_post/'
export const newPollUrl = 'https://ua.streak.tech/handle_post/'
