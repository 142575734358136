import React from 'react'

const Checkbox = ({
  width = 32,
  height = 32,
  color = '#000000',
}) => {
  return (
    <svg viewBox="0 0 64 64" width={width} height={height}>
      <path
        fill={color}
        d="M51.79,54.88H12.24a3,3,0,0,1-3-3V12.24a3,3,0,0,1,3-3H51.86a3,3,0,0,1,3,3V51.86A3.08,3.08,0,0,1,51.79,54.88ZM12.31,51.79H51.79V12.31H12.31V51.79Z"
        transform="translate(-9.22 -9.22)"
      />
    </svg>
  )
}

export default Checkbox
