/* eslint-disable */
import { put, call, takeEvery } from 'redux-saga/effects'
import { prodEndpoints, baseUrl } from '../../utils/apiEndpoints'
import { getRequest, postRequest } from '../../utils/apiRequests'
import {
  getInstrumentsSuccess,
  getInstrumentsFailure,
} from './actions'
import {
  GET_INSTRUMENTS_INIT,
} from './actionTypes'
/* eslint-disable-next-line import/no-cycle */
import store from '../../store'
import { error_msg } from '../../utils/common'
import { BROKERS } from '../../utils/consts'

function* getInstruments(action) {
    const { params, screenName } = action
    try {
      yield put(getInstrumentsSuccess({ data: params.symbols || [] }, screenName))
  } catch(err) {
    yield put(getInstrumentsFailure(err.message || error_msg))
  }
}

export function* getInstrumentsSaga() {
  yield takeEvery(GET_INSTRUMENTS_INIT, getInstruments)
}
