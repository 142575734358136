import { connect } from 'react-redux'

import Info from './Archive'
import { getArchives } from './actions'

const mapStateToProps = (state) => {
  return {
    ...state.archive
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getArchives: (params, headers) => dispatch(getArchives(params, headers)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Info)
