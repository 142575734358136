import React, { Component, createRef } from 'react'

import { ICONS, SPACING, theme } from '../../../Theme'
import { withTheme } from '../../../Theme/ThemeProvider'
// import Button from '../../../UI/Button'
import CustomText from '../../../UI/CustomText'
import Flatlist from '../../../UI/Flatlist'
import HighlightedText from '../../../UI/HighlightedText'
import Icon from '../../../UI/Icon'
import { completeAlgoSummary } from '../../../utils/common'
import { SAMPLES_DISP_LIMIT, technicalslabelMap } from '../../../utils/consts'

class SampleStrategies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showReadMore: false,
      readMore: [],
    }
    this.descRef = createRef()
    this.containerRef = createRef()
  }

  componentDidMount() {
    const {
      getSamples, timeFrame, segSym, user_id, samples
    } = this.props
    if(samples.length === 0) {
      const params = {
        timeFrame,
        stock: segSym,
        user_id,
      }
      this.headers = new Headers()
      this.headers.append('Content-Type', 'application/json')
      getSamples(params, this.headers)
    }
    this.shouldShowReadMore()
  }

  shouldShowReadMore = () => {
    if (this.descRef.current) {
      if (this.descRef.current.scrollWidth <= this.containerRef.current.offsetWidth) {
        this.setState({ showReadMore: false })
      } else {
        this.setState({ showReadMore: true })
      }
    }
  }

  toggleReadMore = (ind) => {
    const { readMore } = this.state
    const readMoreArr = readMore
    const index = readMore.indexOf(ind)
    if(index > -1) {
      readMoreArr.splice(index, 1)
    } else {
      readMoreArr.unshift(ind)
    }
    this.setState({ readMore: readMoreArr })
  }

  renderSampleBtCard = (item, index) => {
    const {
      styles,
      // isDark,
      strategyBacktests,
      baseUrl,
      kiteApp,
      analysisParams,
      // broker,
      exchange,
      stock,
      isDark,
    } = this.props
    const { readMore, showReadMore } = this.state

    const { algo_name, action_type, algo_uuid } = item
    const type = action_type === 'BUY' ? 'Bullish' : 'Bearish'
    const { entryStr, exitStr, algo_desc } = completeAlgoSummary(item)
    const algoDesc = entryStr && !algo_desc ? `${entryStr} ${exitStr ? 'and ' : ''}${exitStr}` : algo_desc
    const {
      text,
      icon,
      bgDark, bg,
    } = technicalslabelMap[type].DEFAULT
    const seg_sym = encodeURIComponent(`${exchange}:${stock}`)
    const readMoreActive = readMore.includes(index)
    // const currentUrl = document.location.ancestorOrigins[0]
    const segment = exchange
    const symbol = stock
    // let streakUrl = ''
    // if(currentUrl !== undefined && currentUrl.includes('streak')) {
    //   // eslint-disable-next-line no-param-reassign
    //   streakUrl = currentUrl
    // } else {
    //   // eslint-disable-next-line no-self-assign
    //   streakUrl = baseUrl
    // }
    const url = `${baseUrl}/backtests?algo_uuid=${algo_uuid}&stocks=${JSON.stringify([{ segment, symbol }])}&stock=${seg_sym}&id=${btoa(algo_uuid)}&sample=true&tech_route=true${kiteApp ? '&tech_link=true' : ''}&${analysisParams}&rb=angel one`

    return (
      <div className={styles.cardContainer} key={index} ref={this.containerRef}>
        <div className={styles.cardHeader}>
          <CustomText weight="semi_bold">{algo_name}</CustomText>
          <HighlightedText
            color={text}
            backgroundColor={isDark ? bgDark : bg}
            text={type}
            iconName={icon}
            iconColor={text}
            iconSize={14}
            styles={{
              padding: `${SPACING.SPACE_2} ${SPACING.SPACE_12}`,
              borderRadius: 4,
            }}
          />
        </div>
        {algoDesc ? (
          <CustomText textRef={this.descRef} weight="regular" color="textGrey" className={`${styles.desc} ${readMoreActive ? '' : styles.shortDesc}`}>
            {algoDesc}
            {showReadMore && (
              <button
                type="button"
                onClick={() => this.toggleReadMore(index)}
                style={{ display: 'block' }}
              >
                <CustomText
                  color={theme.linkColor}
                  weight="semi_bold"
                >
                  {readMoreActive ? 'Read Less' : 'Read More'}
                </CustomText>
              </button>
            )}
          </CustomText>
        ) : null}
        <a
          target="_blank"
          className={styles.backtest_link}
          onClick={() => strategyBacktests(url, algo_name, algo_uuid)}
          href={url}
        >
          Backtest
        </a>
      </div>
    )
  }

  render() {
    const { samples, styles } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.disclaimer}>
          <div
            className={styles.info}
          >
            <Icon
              name={ICONS.INFO}
              className={styles.infoIcon}
              color="#F7C954"
              size={16}
            />
          </div>
          {/* <InfoIcon /> */}
        &nbsp; The following are a set of sample strategies for educational purpose, not to be construed as recommendation.
        </div>
        <Flatlist
          data={samples}
          numberToRender={SAMPLES_DISP_LIMIT}
          showMoreBtn
          showMoreBarStyles={styles.showMoreBar}
          listStyles={styles.list}
        >
          {list => (
            list.map(this.renderSampleBtCard)
          )}
        </Flatlist>
      </div>
    )
  }
}

const stylesheet = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 150,
    // height: 'calc(100vh - 10px)',
    // overflow: 'auto',
    position: 'relative',
    overflowX: 'hidden',
  },
  list: {
  },
  disclaimer: {
    backgroundColor: theme.cardPlaceHolderColor,
    color: '#F7C954',
    letterSpacing: '0.5px',
    fontSize: '0.75rem',
    fontWeight: 400,
    padding: '0.5rem 1rem',
    // marginBottom: '0.75rem',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderRadius: '6px',
    marginTop: '12px',
  },
  cardContainer: {
    margin: `${SPACING.SPACE_28} 0`,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  desc: {
    margin: `${SPACING.SPACE_8} ${SPACING.SPACE_8} ${SPACING.SPACE_20} 0`,
  },
  shortDesc: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },
  btBtn: {
    padding: `${SPACING.SPACE_4} ${SPACING.SPACE_20}`,
  },
  backtest_link: {
    border: '1px solid rgb(0, 19, 244)',
    padding: '6px 20px',
    fontWeight: '500',
    fontSize: '12px',
    backgroundColor: 'rgb(0, 19, 244)',
    borderRadius: '6px',
    color: 'white',
  },
  '@media screen and (max-width: 600px)': {
    container: {
      padding: '0px 20px',
      marginTop: 0,
      // height: 'calc(100vh - 200px)',
    }
  }
}

export default withTheme(stylesheet)(SampleStrategies)
