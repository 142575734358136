import React, { useEffect, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'

import { normalize, EXCHAGE_IMAGE_MAP } from '../Theme'
import { symbolIconsUrl } from '../utils/apiEndpoints'
import { getSegSymFromDC } from '../utils/common'

const DEFAULT_WIDTH = normalize(30)
const DEFAULT_HEIGHT = normalize(30)
// const MAX_RETRY_COUNT = 2

const areEqual = (props, nextProps) => {
  const {
    seg_sym, containerStyle, imgProps,
  } = props
  if(!isEqual(seg_sym, nextProps.seg_sym)
  || !isEqual(containerStyle, nextProps.containerStyle)
  || !isEqual(imgProps, nextProps.imgProps)) {
    return false
  }
  return true
}

const iconCache = {}

const styles = {
  alignItems: 'center',
  display: 'flex',
}

const noFetchSegments = ['NFO-FUT', 'NFO-OPT', 'MCX', 'CDS']

// contains a list of symbols that don't render properly
const stockIconBlacklist = new Set()
const StockIcon = React.memo(({
  seg_sym,
  containerStyle,
  width: newWidth,
  height: newHeight,
  imgProps = {},
}) => {
  let seg = ''
  let sym = ''
  let isDyc = false
  let segSym = ''
  try {
    ({ segSym, isDyc } = getSegSymFromDC(seg_sym))([seg, sym] = segSym.split('_'))
  } catch (error) {
    // console.log(error, 'stock icon error')
  }
  if (!sym) {
    return null
  }
  // so that it will not update state if component is unmounting
  let isUnmounting = false
  // false means not available in cache or not able to fetch
  const [isAvailable, setIsAvailable] = useState(!isDyc)
  // const [Svg, setSvg] = useState(EXCHAGE_IMAGE_MAP[seg] || EXCHAGE_IMAGE_MAP.DEFAULT)
  const svgUri = `${symbolIconsUrl}${sym}.svg`
  const containerRef = useRef()
  // check if available in iconCache otherwise fetch n cache in browser
  useEffect(() => {
    if (!stockIconBlacklist.has(sym) && !isDyc) {
      if (iconCache[sym]) {
        if (containerRef.current) containerRef.current.innerHTML = iconCache[sym]
      } else if (seg && noFetchSegments.includes(seg)) {
        setIsAvailable(false)
      } else {
        fetchAndSaveIcon(svgUri)
      }
    }
  }, [seg_sym])

  useEffect(() => {
    return () => {
      isUnmounting = true
    }
  }, [])
  const fetchAndSaveIcon = (url) => {
    try {
      window.caches.open(url).then((cache) => {
        return cache.match(url).then((response) => {
          if (response) {
            // console.log(' Found response in cache:', response)
            const reader = response.body.getReader()
            return reader.read().then(({ done, value }) => {
              if (!done) {
                const svgImg = String.fromCharCode(...new Uint8Array(value))
                if (containerRef.current) containerRef.current.innerHTML = svgImg
                iconCache[sym] = svgImg
                return ''
              }
              if (!isUnmounting) setIsAvailable(false)
              return ''
            })
          }
          return fetch(url).then((resp) => {
            // console.log('Response from network is', url, resp)
            if (resp.status < 400 && resp.ok) {
              const reader = resp.body.getReader()
              return reader.read().then(({ done, value }) => {
                if (!done) {
                  const svgImg = String.fromCharCode(...new Uint8Array(value))
                  if (containerRef.current) containerRef.current.innerHTML = svgImg
                  iconCache[sym] = svgImg
                  return cache.add(url)
                }
                if (!isUnmounting) setIsAvailable(false)
                return ''
              })
            }
            if (!isUnmounting) setIsAvailable(false)
            return ''
          })
        })
      })
    } catch (error) {
      if (!isUnmounting) setIsAvailable(false)
    }
  }
  const height = newHeight || (containerStyle && containerStyle.height) || DEFAULT_HEIGHT
  const width = newWidth || (containerStyle && containerStyle.width) || DEFAULT_WIDTH
  const defaultImg = isDyc ? EXCHAGE_IMAGE_MAP.DYC
    : EXCHAGE_IMAGE_MAP[seg] || EXCHAGE_IMAGE_MAP.DEFAULT
  return (
    <div
      style={{
        ...styles,
        ...containerStyle,
        height,
        width,
      }}
      ref={containerRef}
    >
      {!isAvailable && <img src={defaultImg} alt={seg_sym} width="100%" height="100%" {...imgProps} />}
    </div>
  )
}, areEqual)

export default StockIcon
