import {
  GET_ANALYSIS_INIT,
  GET_ANALYSIS_SUCCESS,
  GET_ANALYSIS_FAILURE,
  GET_SAMPLES_INIT,
  GET_SAMPLES_SUCCESS,
  GET_SAMPLES_FAILURE,
  POPULAR_SCREENS_SUCCESS,
  POPULAR_SCREENS_FAILURE,
  POPULAR_SCREENS_INIT,
  FETCH_RESISTANCE,
  FETCH_RESISTANCE_SUCCESS,
  FETCH_RESISTANCE_FAILURE,
} from './actionTypes'

export function getAnalysisSuccess(data) {
  return {
    type: GET_ANALYSIS_SUCCESS,
    data
  }
}

export function getAnalysisFailure(error) {
  return {
    type: GET_ANALYSIS_FAILURE,
    error
  }
}

export function getAnalysis(params, headers) {
  const payload = {
    params, headers
  }
  return {
    type: GET_ANALYSIS_INIT,
    payload
  }
}

export function getSamplesSuccess(data) {
  return {
    type: GET_SAMPLES_SUCCESS,
    data
  }
}

export function getSamplesFailure(error) {
  return {
    type: GET_SAMPLES_FAILURE,
    error
  }
}

export function getSamples(params, headers) {
  const payload = {
    params, headers
  }
  return {
    type: GET_SAMPLES_INIT,
    payload
  }
}

export function popularScreensSuccess(data) {
  return {
    type: POPULAR_SCREENS_SUCCESS,
    data,
  }
}

export function popularScreensFailure(error) {
  return {
    type: POPULAR_SCREENS_FAILURE,
    error,
  }
}

export function popularScreens(params, headers) {
  const payload = {
    params, headers
  }
  return {
    type: POPULAR_SCREENS_INIT,
    payload,
  }
}

export function fetchResistanceSuccess(data, stockList) {
  return {
    type: FETCH_RESISTANCE_SUCCESS,
    data,
    stockList
  }
}

export function fetchResistanceFailure(error) {
  return {
    type: FETCH_RESISTANCE_FAILURE,
    error
  }
}

export function fetchResistance(params, headers) {
  return {
    type: FETCH_RESISTANCE,
    params,
    headers
  }
}
