import React, { PureComponent } from 'react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import CustomText from './CustomText'

import { ASSETS, SPACING } from '../Theme'
import { withTheme } from '../Theme/ThemeProvider'

const {
  PointerResistance, BAR_RESISTANCE, XS_BAR_RESISTANCE, POINTER_DARK, XS_POINTER_DARK,
} = ASSETS

class ResistanceGraph extends PureComponent {
  constructor(props) {
    super(props)
    this.pointerWidth = props.pointerWidth || 40
    this.pointerHeight = props.isDark ? '50%' : '26%'
    this.pointerSrc = props.isDark ? POINTER_DARK : PointerResistance
    this.pointerRef = React.createRef()
    this.graphViewRef = React.createRef()

    this.state = {
      graphWidth: 1,
    }
    this.pointerHeightMultiplier = props.isDark ? 1 : 6
    this.barImageSrc = BAR_RESISTANCE
    this.sepWidth = 16
    this.graphViewRef = React.createRef()
  }

  componentDidMount() {
    if (this.graphViewRef.current) {
      const { width } = this.graphViewRef.current.getBoundingClientRect()
      if (width <= 100) {
        const { isDark } = this.props
        this.barImageSrc = XS_BAR_RESISTANCE
        this.pointerSrc = isDark ? XS_POINTER_DARK : PointerResistance
        this.sepWidth = 8
        this.pointerHeight = isDark ? 16 : 10
      }
      this.setState({ graphWidth: width })
    }
  }

  componentDidUpdate(prevProps) {
    const { analysis } = this.props
    if (!isEqual(analysis, prevProps.analysis)) {
      this.calcPointerPos()
    }
  }

  calculator = (v1, v2, constantWidth, singleWidth, ltp) => {
    const multiplier = singleWidth / (parseFloat(v1) - parseFloat(v2))
    return constantWidth + (multiplier * (ltp - parseFloat(v2)))
  }

  calcPointerPos = () => {
    const { graphWidth } = this.state
    let leftPos = 50
    const { ltp, analysis = {} } = this.props
    const {
      s3 = 0.0,
      s2 = 0.0,
      s1 = 0.0,
      pp = 0.0,
      r1 = 0.0,
      r2 = 0.0,
      r3 = 0.0,
    } = analysis
    const singleWidth = graphWidth / 6
    let pct = false
    let pointerOffset = (this.pointerWidth / 2)
    if ((ltp === 0.0) || isEmpty(analysis)) {
      leftPos = 50
      pct = true
      pointerOffset *= (100 / graphWidth)
    } else if (ltp <= parseFloat(s3)) {
      leftPos = 0
    } else if (ltp >= parseFloat(r3)) {
      pct = true
      pointerOffset *= (100 / graphWidth)
      leftPos = 100
    } else if (ltp <= parseFloat(s2)) {
      leftPos = this.calculator(s2, s3, singleWidth * 0, singleWidth, ltp)
      leftPos = leftPos.toFixed(2)
    } else if (ltp <= parseFloat(s1)) {
      leftPos = this.calculator(s1, s2, singleWidth * 1, singleWidth, ltp)
      leftPos = leftPos.toFixed(2)
    } else if (ltp <= parseFloat(pp)) {
      leftPos = this.calculator(pp, s1, singleWidth * 2, singleWidth, ltp)
      leftPos = leftPos.toFixed(2)
    } else if (ltp <= parseFloat(r1)) {
      leftPos = this.calculator(r1, pp, singleWidth * 3, singleWidth, ltp)
      leftPos = leftPos.toFixed(2)
    } else if (ltp <= parseFloat(r2)) {
      leftPos = this.calculator(r2, r1, singleWidth * 4, singleWidth, ltp)
      leftPos = leftPos.toFixed(2)
    } else if (ltp < parseFloat(r3)) {
      leftPos = this.calculator(r3, r2, singleWidth * 5, singleWidth, ltp)
      leftPos = leftPos.toFixed(2)
    } else {
      pct = true
      pointerOffset *= (100 / graphWidth)
    }
    // this.pointerHeight = typeof this.pointerHeight === 'string' ? `${((Math.abs(50
    // - Number(leftPos * (100 / graphWidth))) / this.pointerHeightMultiplier) + 20)}%`
    //   : this.pointerHeight
    return {
      leftPos: pct ? ((leftPos - pointerOffset) * graphWidth) / 100
        : Number(leftPos - pointerOffset),
    }
  }

  render() {
    const {
      styles, graphHeight = 100, graphViewWidth = '100%', showLabels,
    } = this.props
    const { leftPos } = this.calcPointerPos()
    return (
      <div className={styles.container}>
        <div
          className={styles.graphView}
          style={{ width: graphViewWidth }}
          ref={this.graphViewRef}
        >
          <img src={this.barImageSrc} style={{ maxHeight: graphHeight }} alt="resistance" className={styles.barImg} />
          <img
            src={this.pointerSrc}
            height={this.pointerHeight}
            width={this.pointerWidth}
            className={styles.pointer}
            style={{
              left: leftPos, // / `calc(${leftPos} - 10px)`,
            }}
            alt="technical_pointer"
          />
        </div>
        {showLabels && (
        <div className={styles.labels}>
          <CustomText size="small" style={{ }}>S3</CustomText>
          <CustomText size="small" style={{ textAlign: 'center' }}>S2</CustomText>
          <CustomText size="small" style={{ textAlign: 'center' }}>S1</CustomText>
          <CustomText size="small" style={{ textAlign: 'center' }} />
          <CustomText size="small" style={{ textAlign: 'center' }}>R1</CustomText>
          <CustomText size="small" style={{ textAlign: 'center' }}>R2</CustomText>
          <CustomText size="small" style={{ textAlign: 'right' }}>R3</CustomText>
        </div>
        )}
      </div>
    )
  }
}

const stylesheet = ({
  container: {
    width: '100%',
  },
  graphView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  pointer: {
    position: 'absolute',
    // zIndex: 3,
    transition: 'left 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)',
  },
  barImg: {
    width: '100%',
  },
  pointerImg: {
  },
  labels: {
    display: 'flex', justifyContent: 'space-between', marginTop: SPACING.SPACE_10, marginBottom: SPACING.SPACE_20,
  },
})

export default withTheme(stylesheet)(ResistanceGraph)
