import { createMuiTheme } from '@material-ui/core'

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
}

export const getColors = (theme) => {
  const COLORS = {

    purple: '#673AB7',

    textDark: '#6e6e6e',
    textDark_2: '#383838',
    textLight: '#9ea1a4',

    textLight_100: '#666666',

    grey: '#c7c7c7',
    greyLight: '#f3f3f3',
    greyLight_1: '#444444',
    hover: '#f9f9f9',

    greyLight_3: '#e7e7e7',
    greyLight_4: '#999999',
    greyLight_5: '#dee7f1',

    green: '#06d092',
    greenLight: '#d7f7ed',
    greenText: '#1ec18f',
    greenDark: '#0edd1c',

    red: '#f43b51',
    redLight: '#fae2e5',
    redText: '#ff4258',

    orangeText: '#f55b12',
    orangeBg: '#fce7e6',
    orangeDark: '#f44b12',
    orangeLight_1: '#f2784b',

    blue: '#0088ff',
    blueDark: '#027be4',
    blueLight: '#d6ecff',
    blueLight_1: '#d6ecff',
    blueLight_2: '#f5f7fd',
    blueLight_3: '#76b3f6',

    blueBg: '#eef0ff',
    blueText: '#253bde',

    white: '#fff',
    black: '#000000',

    golden: '#F7C954',
    goldenLight: '#fff8e5',

    yellow: '#f9c85f',
    yellowLight: '#ffefbc',

    brown: '#bc892e',
    sidebar: '#353333',
    background: '#ffffff',
    underline: '#e2e2e2',
    disabled: '#bfc7d1',
    bghighlight: '#f3f3f3',
    activeText: '#253bde',
    //
    // Background colors
    // #111111 - Layer 0 background color
    // #181818 - Layer 1 background color
    // #1F1F1F - Layer 2 background color. Should be used for iframes
    // like smallcase fundamentals, streak technicals etc
    // #1C1C1C - highlight background color used on top of Layer 2
    // (To highlight particular block or section)

    // Text colors
    // #BBBBBB - Default text color
    // #666666 - Secondary dim text color
    // #E25F5B - Orange
    // #4987EE - Blue
    // #E25F5B - Red
    // #5B9A5D - Green
  }
  if (theme === THEME.DARK) {
    return {
      ...COLORS,
      textDark: '#BBBBBB',
      textDark_2: '#666666',
      textLight: '#666666',
      background: '#1F1F1F',
      bghighlight: '#2F2F2F',
      activeText: '#BBBBBB',
      hover: '#3F3F3F',

      blueLight: '#2b2f33',
      greyLight: '#313131',
      textLight_100: '#e0e0e0',
      orangeText: '#f88c59',
      orangeBg: '#7e7473',
      blueBg: '#5f6066',
      blueText: '#929def',
    }
  }
  return COLORS
}

export const avatarColors = [
  {
    color: '#946eff',
    shadow: '0px 3px 10px 0px rgba(148, 110, 255, 0.5)',
    text: '#f6f2ff',
  },
  {
    color: '#f77593',
    shadow: '0px 3px 10px 0px rgba(247, 117, 147, 0.5)',
    text: '#ffeded',
  },
  {
    color: '#869ffb',
    shadow: '0px 3px 10px 0px rgba(134, 159, 251, 0.5)',
    text: '#eef1ff',
  },
  {
    color: '#f4d677',
    shadow: '0px 3px 10px 0px rgba(244, 214, 119, 0.5)',
    text: '#fff6da',
  },
  {
    color: '#61e6ac',
    shadow: '0px 3px 10px 0px rgba(97, 230, 172, 0.5)',
    text: '#e2fff2',
  },
  {
    color: '#88daf2',
    shadow: '0px 3px 10px 0px rgba(136, 218, 242, 0.5)',
    text: '#e9faff',
  },
  {
    color: '#ffd85e',
    shadow: '0px 3px 10px 0px rgba(255, 216, 94, 0.5)',
    text: '#fff8e1',
  },
]

export const getTheme = (theme) => {
  return createMuiTheme({
    palette: {
      primary: {
        main: getColors(theme).blue,
      },
      text: {
        secondary: getColors(theme).textLight,
        primary: getColors(theme).textDark_2,
      },
      divider: getColors(theme).greyLight,
    },
    typography: {
      useNextVariants: true,
      fontSize: 14,
      fontFamily: ['Open Sans', 'Roboto'].join(','),
    },
    shape: {
      borderRadius: 2,
    },
    spacing: {
      unit: 4,
    },
    colors: getColors(theme),
  })
}
