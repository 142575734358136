import React, {
  useCallback, useEffect, useRef,
} from 'react'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomText from './CustomText'
import { SPACING, theme } from '../Theme'
import { useTheme } from '../Theme/ThemeProvider'

let initialMount = true

const CustomHorizontalTab = ({
  tabs,
  tabChangeHandler,
  defaultActiveTab,
  style,
  tabStyles,
  dragBtnStyles,
  activeTabColor,
  tabColor,
  //   loading,
  //   loadingText,
  labelRenderer,
  labelKey,
  valueKey,
  labelProps = {},
  extraStyles = {},
  changeKey,
  disableActiveTab,
}) => {
  const dragBtnRef = useRef(null)
  const selectedBtnRef = useRef(null)
  const styles = stylesheet()
  const { isDark } = useTheme()
  // const [activeTab, toggleTab] = useState(defaultActiveTab)
  const debouncedHandler = useCallback(
    debounce((e, value, item) => tabChangeHandler(value, item, changeKey), 50), [],
  )
  useEffect(() => {
    const calcTimer = setTimeout(() => {
      if (defaultActiveTab && tabs.length > 0 && dragBtnRef.current && selectedBtnRef.current) {
        initialMount = false
        const selectedEle = selectedBtnRef.current
        const {
          offsetLeft, offsetTop, offsetWidth, offsetHeight,
        } = selectedEle
        const dragBtnEle = dragBtnRef.current
        dragBtnEle.style.left = `${offsetLeft}px` || 0
        dragBtnEle.style.width = `${offsetWidth}px`
        dragBtnEle.style.top = `${offsetTop}px`
        dragBtnEle.style.height = `${offsetHeight}px`
        dragBtnEle.style.opacity = 1
      }
    }, initialMount ? 800 : 50)

    return () => {
      if (calcTimer) clearTimeout(calcTimer)
    }
  }, [defaultActiveTab, tabs])
  const onTabChange = (e, value, item, index, active) => {
    e.stopPropagation()
    e.preventDefault()
    if (active && tabs.length === 2) {
      const newIndex = index === 1 ? 0 : 1
      const newItem = tabs[newIndex]
      let newValue = newItem
      if (typeof newItem === 'object') {
        newValue = valueKey ? newItem[valueKey] : newItem.value
      }
      debouncedHandler(e, newValue, newItem)
      return
    }
    // toggleTab(value)
    debouncedHandler(e, value, item)
  }
  let calcTabColor = tabColor
  let calcActiveTabColor = activeTabColor
  // const onDragStart = (e) => {
  //   e.preventDefault()
  //   e.dataTransfer.setData('tabBtn', e.target.id)
  // }
  // const onDrop = (e) => {
  //   e.preventDefault()
  //   const data = e.dataTransfer.getData('tabBtn')
  //   e.target.appendChild(data)
  // }
  // const onDragOver = (e) => {
  //   e.preventDefault()
  // }
  return (
    <div className={classnames(styles.tabContainer, style)} style={extraStyles}>
      <div id="dragBtn" ref={dragBtnRef} className={classnames(styles.dragBtn, dragBtnStyles)} />
      {tabs.map((item, index) => {
        let label = item
        let value = item
        let shortLabel = item
        if (typeof item === 'object') {
          ({ shortLabel } = item)
          label = labelKey ? item[labelKey] : item.label
          value = valueKey ? item[valueKey] : item.value
          calcTabColor = item.textColor || calcTabColor
          calcActiveTabColor = isDark ? 'white' : (item.activeTextColor || calcActiveTabColor)
        }
        const active = defaultActiveTab === value
        return (
          <button
            key={value}
            id={value}
            name={value}
            type="button"
            onClick={(e) => { onTabChange(e, value, item, index, active) }}
            className={classnames(styles.tabBtn, tabStyles)}
            ref={active ? selectedBtnRef : null}
            disabled={disableActiveTab && active}
            // onDrop={onDrop}
            // onDragOver={onDragOver}
          >
            {labelRenderer ? labelRenderer({ item, active, isDark }) : (
              <CustomText
                className={styles.btnText}
                center
                weight="medium"
                style={{ whiteSpace: 'nowrap' }}
                color={active ? calcActiveTabColor : calcTabColor}
                {...labelProps}
              >
                {active || !shortLabel ? label : shortLabel}
              </CustomText>
            )}
          </button>
        )
      })}
    </div>
  )
}

const stylesheet = makeStyles(() => ({
  tabContainer: {
    display: 'flex',
    borderRadius: 10,
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_14}`,
    backgroundColor: theme.horizontalTabBg,
    position: 'relative',
  },
  tabBtn: {
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_8}`,
    flex: 1,
    position: 'relative',
    borderRadius: 4,
  },
  btnText: {
    transition: 'all 100ms ease-in 50ms',
  },
  dragBtn: {
    borderRadius: 10,
    backgroundColor: theme.bgPrimary,
    cursor: 'pointer',
    position: 'absolute',
    transition: 'all 200ms var(--anim-func-ease)',
    opacity: 0,
  },
}))

export default CustomHorizontalTab

CustomHorizontalTab.defaultProps = {
  tabChangeHandler: () => {},
  activeTabColor: 'black',
  tabColor: 'textSecondary',
  changeKey: '',
  disableActiveTab: true,
  // loading: false,
  // loadingText: 'loading...',
}

CustomHorizontalTab.propTypes = {
  tabs: PropTypes.array.isRequired,
  tabChangeHandler: PropTypes.func,
  activeTabColor: PropTypes.string,
  tabColor: PropTypes.string,
  defaultActiveTab: PropTypes.string.isRequired,
  changeKey: PropTypes.string,
  disableActiveTab: PropTypes.bool,
  // loading: PropTypes.bool,
  // loadingText: PropTypes.string,
}
