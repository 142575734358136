import { connect } from 'react-redux'

import Info from './Info'
import {
  fetchResistance, getAnalysis, getSamples, popularScreens
} from './actions'
import { technicalPairs } from '../Instruments/actions'

const mapStateToProps = (state) => {
  return {
    ...state.info
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAnalysis: (params, headers) => dispatch(getAnalysis(params, headers)),
    getSamples: (params, headers) => dispatch(getSamples(params, headers)),
    getPopularScreens: (params, headers) => dispatch(popularScreens(params, headers)),
    fetchResistance: (params, headers) => dispatch(fetchResistance(params, headers)),
    subscribePair: (pairs, screenName) => technicalPairs(dispatch, pairs, screenName),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Info)
