/* eslint-disable import/no-cycle */
import { all, fork } from 'redux-saga/effects'
import * as infoSagas from './containers/Info/Info-saga'
import * as archiveSagas from './containers/Archive/Archive-saga'
import * as commonSagas from './containers/Common/Common-saga'
import * as instrmentsSagas from './containers/Instruments/Instruments-saga'

const info = Object.values(infoSagas).map((saga) => {
  return fork(saga)
})

const archive = Object.values(archiveSagas).map((saga) => {
  return fork(saga)
})

const common = Object.values(commonSagas).map((saga) => {
  return fork(saga)
})

const instrment = Object.values(instrmentsSagas).map((saga) => {
  return fork(saga)
})

export default function* root() {
  yield all([
    ...info,
    ...archive,
    ...common,
    ...instrment,
  ])
}
