import React from 'react'
import styles from './Loader.css'

const Loader = ({
  num = 5,
  extraClass = '',
  color = '',
}) => {
  return (
    <div className={`${styles.dot_container} ${extraClass}`}>
      <div className={styles.dot} style={{ backgroundColor: color }} />
      <div className={styles.dot} style={{ backgroundColor: color }} />
      <div className={styles.dot} style={{ backgroundColor: color }} />
      {num === 5 ? <div className={styles.dot} style={{ backgroundColor: color }} /> : null}
      {num === 5 ? <div className={styles.dot} style={{ backgroundColor: color }} /> : null}
    </div>
  )
}

export default Loader
