import { call, put, takeLatest, } from 'redux-saga/effects'
import { GET_ARCHIVES_INIT } from './actionTypes'
import {
  getArchivesSuccess,
  getArchivesFailure,
} from './actions'
import { endpoints } from '../../utils/apiEndpoints'
import { postRequest } from '../../utils/apiRequests'

function* archive(action) {
  try {
    const { params, headers } = action.payload
    const url = `${endpoints.archive}`
    const data = yield call(postRequest, url, params, headers, false)
    if (!data.error && data.data) {
      yield put(getArchivesSuccess(data.data))
    } else {
      yield put(getArchivesFailure(data.error_msg))
    }
  } catch (err) {
    yield put(getArchivesFailure('Unable to get archive'))
  }
}

export function* archiveSaga() {
  yield takeLatest(GET_ARCHIVES_INIT, archive)
}
