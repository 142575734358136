import axios from 'axios'
import { getEncodedParams } from './common'

export const getRequest = async (url, params, headers) => {
  let queryParams = ''
  if (params && Object.keys(params).length) {
    queryParams = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&')
    queryParams = `?${queryParams}`
  }
  const final_url = `${url}${queryParams}`
  const data = await axios.get(final_url, {
    headers,
    timeout: 45000,
  })
    .then(resp => resp.data)
    .catch((err) => {
      let error_msg = 'Error fetching data'
      if (err.response.status === 401) {
        error_msg = 'Unauthorized'
      } else if (err.response.status === 500) {
        error_msg = 'Internal server error, try again later'
      } else if (err.response) {
        error_msg = err.response.data.data
      } else if (err.request) {
        error_msg = 'Error getting response'
      }
      return {
        error: true,
        error_msg
      }
    })
  return data
}

export const postRequest = async (url, params, headers, encode = true) => {
  const data = await axios({
    method: 'post',
    url,
    data: encode ? getEncodedParams(params) : params,
    headers,
    timeout: 45000
  })
    .then(resp => resp.data)
    .catch((err) => {
      let error_msg = 'Error getting response'
      if (err.response.status === 401) {
        error_msg = 'Unauthorized'
      } else if (err.response) {
        error_msg = err.response.data.error_msg // eslint-disable-line
        if (err.status === 500) {
          error_msg = 'Error connecting to server'
        }
      } else if (err.request) {
        error_msg = 'Error getting response'
      }
      return {
        error: true,
        error_msg
      }
    })
  return data
}
