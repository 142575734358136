export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const RESET_NOTIFICATION_COUNT = 'RESET_NOTIFICATION_COUNT'

export const FETCH_ALGOS_INIT = 'FETCH_ALGOS_INIT'
export const FETCH_ALGOS_SUCCESS = 'FETCH_ALGOS_SUCCESS'
export const FETCH_ALGOS_FAILURE = 'FETCH_ALGOS_FAILURE'

export const ACTIVE_SCREEN = 'ACTIVE_SCREEN'

export const TOGGLE_TERMS_PAGE = 'TOGGLE_TERMS_PAGE'

export const CLEAR_SEARCH_RESP = 'CLEAR_SEARCH_RESP'

export const REFRESH_ALGO = 'REFRESH_ALGO'

export const REFRESH_SCANS = 'REFRESH_SCANS'

export const FETCH_PROFILE_CAROUSEL = 'FETCH_PROFILE_CAROUSEL'
export const FETCH_PROFILE_CAROUSEL_SUCCESS = 'FETCH_PROFILE_CAROUSEL_SUCCESS'
export const FETCH_PROFILE_CAROUSEL_FAILURE = 'FETCH_PROFILE_CAROUSEL_FAILURE'

export const TOGGLE_ROOT_MODAL = 'TOGGLE_ROOT_MODAL'

export const POLL_USER = 'POLL_USER'

export const UPDATE_WEBSITE_CONFIG = 'UPDATE_WEBSITE_CONFIG'

export const TOGGLE_AUTH_GREETING = 'TOGGLE_AUTH_GREETING'

export const TOGGLER_TOUR = 'TOGGLER_TOUR'
