/* eslint-disable react/no-did-update-set-state */
import React from 'react'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import CustomText from './CustomText'
import Button from './Button'

const loadOffset = 50
// DANGER last element getting cut
class Flatlist extends React.Component {
  constructor(props) {
    super(props)
    const { numberToRender, data = [], showMoreBtn = false } = props
    this.state = {
      containerHeight: 0,
      pageSize: numberToRender,
      page: 1,
      pages: Math.ceil(data.length / numberToRender),
      list: numberToRender ? data.slice(0, numberToRender) : data,
    }
    this.showMoreBtn = showMoreBtn
    this.listRef = React.createRef()
    this.main = document.getElementById('main')
  }

  componentDidMount() {
    if (!this.showMoreBtn) {
      this.calcHeight()
      if (this.main) this.main.addEventListener('scroll', this.onScroll)
    }
  }

  componentDidUpdate(prevProps) {
    const { data, numberToRender } = this.props
    if (!isEqual(data, prevProps.data)) {
      if (numberToRender) {
        this.setState({
          page: 1,
          pages: Math.ceil(data.length / numberToRender),
          list: data.slice(0, numberToRender),
        }, () => { if (!this.showMoreBtn) this.calcHeight() })
      } else {
        this.setState({ list: data }, this.calcHeight())
      }
    }
  }

  componentWillUnmount() {
    if (this.main) this.main.removeEventListener('scroll', this.onScroll)
  }

  onScroll = debounce((ev) => {
    const { containerHeight } = this.state
    const { scrollTop } = ev.target
    const offset = containerHeight - window.innerHeight
    if (scrollTop > offset - loadOffset) {
      const { loadMore } = this.props
      if (loadMore) {
        loadMore()
      } else {
        this.loadMore()
      }
    }
  }, this.props.numberToRender ? 300 : 600)

  calcHeight = () => {
    if (this.listRef.current) {
      const { offsetHeight, offsetTop } = this.listRef.current
      this.setState({ containerHeight: offsetHeight + offsetTop })
    }
  }

  loadMore = () => {
    const { data } = this.props
    this.setState((prevState) => {
      const {
        list, page, pageSize, pages,
      } = prevState
      if (page + 1 > pages) {
        return {}
      }
      const newPage = page + 1
      const modList = [...list, ...data.slice(page * pageSize, newPage * pageSize)]
      return { list: modList, page: newPage }
    }, () => this.calcHeight())
  }

  render() {
    const {
      listStyles, numberToRender, data = [],
      isLoading, children, showMoreBarStyles = '',
    } = this.props
    const { list, pages, page } = this.state
    // console.log(page, pages, 'lol state page')
    return (
      <div
        ref={this.listRef}
        className={listStyles}
      >
        {children(list)}
        {/* {list.map((item, index) => renderItem({ item, index }))} */}
        {isLoading && <CustomText center style={{ margin: '10px' }}>loading...</CustomText>}
        {this.showMoreBtn && data.length > numberToRender && pages !== page && (
          <div
            className={showMoreBarStyles}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Button
              mode="text"
              labelColor="linkColor"
              transparent
              text="View more"
              onPress={this.loadMore}
            />
          </div>
        )}
      </div>
    )
  }
}

export default Flatlist
